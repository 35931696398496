import { Card, em, Flex, Loader, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect } from 'react'
import CategoryCard from '../../../components/CategoryCard.tsx'
import { getAllCategoriesAction } from '../../../store/category/categoriesActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'

const CategoriesList = () => {
	const dispatch = useAppDispatch()
	const { categories, loaders } = useAppSelector((state) => state.categories)
	const isSmall = useMediaQuery(`(max-width: ${em(768)})`)

	useEffect(() => {
		dispatch(getAllCategoriesAction())
	}, [])

	return (
		<Flex w='100%' direction='column'>
			<Card w='100%' pos='fixed' style={{ zIndex: '100' }}>
				<Flex w='100%' justify='center'>
					<Flex
						justify='space-between'
						align='center'
						w={isSmall ? '100%' : 1440}
					>
						<Title order={3}>Explore Categories</Title>
						{/*<Button radius="xl" color={Colors.primaryColor}>Create Coupon</Button>*/}
					</Flex>
				</Flex>
			</Card>
			<Flex direction='column' justify='center' align='center' mt={60}>
				<Flex maw={isSmall ? '100%' : 1240} wrap='wrap' justify='center'>
					{loaders.gettingAllCategories ? (
						<Flex h='90vh' w='100%' justify='center' align='center'>
							<Loader />
						</Flex>
					) : (
						categories &&
						categories?.categories?.map((category, index) => (
							<CategoryCard category={category} key={index} />
						))
					)}
				</Flex>
			</Flex>
		</Flex>
	)
}

export default CategoriesList
