import { Button, Center, Flex, Image, Title } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import updated from '../../assets/Illustration.png'
import CenterLayout from '../../components/centerlayout/CenterLayout.tsx'
import Colors from '../../constants/colors.ts'

const PasswordUpdated = () => {
	const navigate = useNavigate()
	return (
		<CenterLayout>
			<Center>
				<Flex direction='column' align='center'>
					<Image src={updated} w={150} h={150} />
					<Title>Password Updated</Title>
					<span style={{ fontWeight: 400, color: '#A8AEBF' }}>
						Your password has been changed successfully
					</span>
				</Flex>
			</Center>
			<Button
				fw={200}
				radius='xl'
				my='lg'
				size='lg'
				color={Colors.primaryColor}
				onClick={() => {
					navigate('/dashboard')
				}}
				w='100%'
				mt='lg'
			>
				Login
			</Button>
		</CenterLayout>
	)
}

export default PasswordUpdated
