import {Box, Button, Card, Divider, em, Flex, Image, LoadingOverlay, ScrollArea, Text, Title} from "@mantine/core";
import {Fragment, useEffect, useState} from "react";
import {getAllCartItems} from "../../../store/product/productActions.ts";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import MyBagItems from "./MyBagItems.tsx";
import {useMediaQuery} from "@mantine/hooks";
import {useNavigate} from "react-router-dom";
import {round} from "../../../utils/roundOffFunction.ts";
import Colors from "../../../constants/colors.ts";

const MyBag = () => {
    const {cart, loaders} = useAppSelector(state => state.product)
    const dispatch = useAppDispatch()
    const [totalPrice, setTotalPrice] = useState(0)
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getAllCartItems())
    }, []);

    useEffect(() => {
        const sum = 0
        setTotalPrice(parseFloat(sum.toFixed(2)))
    }, [cart]);

    return (
        <Flex w="100%" align="center" direction="column">
            <LoadingOverlay
                visible={loaders.gettingAllCartItems}
                zIndex={1000}
                overlayProps={{radius: 'sm', blur: 2}}
                loaderProps={{
                    color: Colors.primaryColor,
                    type: "bars"
                }}
                // loaderProps={{color: Colors.primaryColor}, type: 'bars'}}
            />
            <Flex w={isMobile ? "100%" : "70%"} h="10%" align="center" gap="xs" my="lg">
                <Title order={3} ml={isMobile ? "sm" : "xl"}>My Bag </Title>
                <Text>({cart?.cart?.details[0]?.products?.length} Items)</Text>
            </Flex>
            <Flex w="100%" justify="center" gap="xl" direction={isMobile ? "column" : "row"}>
                {cart?.cart?.details?.length <= 0 ?
                    <Flex w="100%" direction="column" justify="center" align="center" my="xl" py="xl">
                        <Box h={200} w={200}>
                            <Image h={190} w={190}
                                   src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724744725/Icons/izqyvmnxdaxekaf7hiyt.png"/>
                        </Box>
                        <Text>Add products to <span style={{color: Colors.primaryColor}}>CRAZY Cart!</span></Text>
                    </Flex> :
                    <>
                        <Flex w={isMobile ? "100%" : "50%"} direction="column" gap="sm" mb={isMobile ? "" : "sm"}>
                            <ScrollArea h={isMobile ? "480px" : "100%"}>
                                {cart?.cart?.details?.map((detail, index) => {
                                    return (
                                        <MyBagItems key={index} items={detail} totalAmount={totalPrice}/>
                                    )
                                })}
                            </ScrollArea>
                        </Flex>
                        {isMobile ?
                            <Flex w="100%" pos="fixed" bottom={0}>
                                <Card radius="lg" w="100%" h="60%" shadow="xs">
                                    <Flex gap="sm" direction="column" h="100%" justify="space-between" align="center">
                                        <Flex direction="column" gap="sm" w="100%">
                                            <Text size={isMobile ? "sm" : ""} fw={600}>Order Summary</Text>
                                            {cart?.cart?.total?.map((subtotal, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <Flex justify="space-between">
                                                            <Text size={isMobile ? "sm" : ""}
                                                                  c="#767C8C">{subtotal?.title} </Text>
                                                            <Text size={isMobile ? "sm" : ""}
                                                                  c="#767C8C">{round(subtotal?.price?.amount)} {" "}{subtotal?.price?.currency}</Text>
                                                        </Flex>
                                                        <Divider my={isMobile ? "" : "sm"} variant="dotted"/>
                                                    </Fragment>
                                                )
                                            })}
                                            <Flex justify="space-between">
                                                <Text size={isMobile ? "sm" : ""}
                                                      fw={600}>{cart?.cart?.orderTotal?.title}</Text>
                                                <Text
                                                    size={isMobile ? "sm" : ""}>{round(cart?.cart?.orderTotal?.price?.amount)} {" "}{cart?.cart?.orderTotal?.price?.currency}</Text>
                                            </Flex>
                                        </Flex>
                                        <Button onClick={() => navigate("/payment")} component="a" fw={200}
                                                w="75%"
                                                size="xxl"
                                                radius="xl" color={Colors.primaryColor}>Go
                                            to
                                            Checkout</Button>
                                    </Flex>
                                </Card>
                            </Flex>
                            :
                            <Flex w={isMobile ? "100%" : "35%"} h="100%">
                                <Card radius="lg" w="100%" h="60%" shadow="xs">
                                    <Flex gap="sm" direction="column" h="100%" justify="space-between" align="center">
                                        <Flex direction="column" gap="sm" w="100%">
                                            <Text size={isMobile ? "sm" : ""} fw={600}>Order Summary</Text>
                                            {cart?.cart?.total?.map((subtotal) => {
                                                return (
                                                    <>
                                                        <Flex justify="space-between">
                                                            <Text size={isMobile ? "sm" : ""}
                                                                  c="#767C8C">{subtotal?.title} </Text>
                                                            <Text size={isMobile ? "sm" : ""}
                                                                  c="#767C8C">{round(subtotal?.price?.amount)} {" "}{subtotal?.price?.currency}</Text>
                                                        </Flex>
                                                        <Divider my={isMobile ? "" : "sm"} variant="dotted"/>
                                                    </>
                                                )
                                            })}
                                            <Flex justify="space-between">
                                                <Text size={isMobile ? "sm" : ""}
                                                      fw={600}>{cart?.cart?.orderTotal?.title}</Text>
                                                <Text
                                                    size={isMobile ? "sm" : ""}>{round(cart?.cart?.orderTotal?.price?.amount)} {" "}{cart?.cart?.orderTotal?.price?.currency}</Text>
                                            </Flex>
                                        </Flex>
                                        <Button onClick={() => navigate("/payment")} component="a" fw={200}
                                                w="75%"
                                                size="lg"
                                                radius="xl" color={Colors.primaryColor}>Go
                                            to
                                            Checkout</Button>
                                    </Flex>
                                </Card>
                            </Flex>
                        }
                    </>
                }
            </Flex>
        </Flex>
    );
};

export default MyBag;