const reviews = [
	{
		userImage: 'https://example.com/images/user1.jpg',
		name: 'Sarah Johnson',
		reviewDate: '2024-12-05',
		message:
			'I absolutely love shopping here! The variety of products and the ease of navigating the site make it a delightful experience every time. Highly recommend!',
	},
	{
		userImage: 'https://example.com/images/user2.jpg',
		name: 'David Lee',
		reviewDate: '2024-11-22',
		message:
			"Fast delivery and great customer service. I've bought everything from groceries to pet food, and I'm always satisfied with the quality!",
	},
	{
		userImage: 'https://example.com/images/user3.jpg',
		name: 'Emily Davis',
		reviewDate: '2024-11-15',
		message:
			'An amazing online store with the best prices! I found all my favorite products and the checkout process was so smooth.',
	},
	{
		userImage: 'https://example.com/images/user4.jpg',
		name: 'Michael Thompson',
		reviewDate: '2024-10-30',
		message:
			'The customer support team is fantastic! They helped me with an issue I had and resolved it quickly. A really user-friendly site!',
	},
	{
		userImage: 'https://example.com/images/user5.jpg',
		name: 'Sophia Brown',
		reviewDate: '2024-10-25',
		message:
			"Love the convenience of ordering from this site. The products always arrive fresh and the variety is great. I'll keep coming back!",
	},
	{
		userImage: 'https://example.com/images/user6.jpg',
		name: 'John Williams',
		reviewDate: '2024-10-10',
		message:
			"I'm a frequent shopper here, and I can always find what I need. Great prices and even better customer service!",
	},
	{
		userImage: 'https://example.com/images/user7.jpg',
		name: 'Olivia Martinez',
		reviewDate: '2024-09-29',
		message:
			'A great experience every time! The range of products is amazing and the website is so easy to use. Delivery is fast and reliable!',
	},
]


export default reviews