import {
	Box,
	Button,
	Card,
	em,
	Flex,
	Group,
	Image,
	rem,
	Stepper,
	Text,
	Title,
} from '@mantine/core'
import { FileWithPath } from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import orderComplete from '../../../assets/Illustration.png'
import axiosInstance from '../../../config/axios.config.ts'
import {
	IAddProductResponse,
	IProductAdd,
} from '../../../interfaces/business/seller/IProductCreate.ts'
import { notificationSystem } from '../../../utils/notificationSystem.ts'
import AddBasicInfo from './add-product-steps/AddBasicInfo.tsx'
import AddProductDescription from './add-product-steps/AddProductDescription.tsx'
import AddProductSpecification from './add-product-steps/AddProductSpecification.tsx'
import Colors from '../../../constants/colors.ts'

const AddProduct = () => {
	const { id } = useParams()
	const [active, setActive] = useState(0)
	const navigate = useNavigate()
	const [images, setImages] = useState<File[]>([])
	const [coverImage, setCoverImage] = useState<FileWithPath[]>([])
	const [loading, setLoading] = useState(false)
	const isLaptop = useMediaQuery(`(max-width: ${em(992)})`)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const form = useForm<IProductAdd>({
		initialValues: {
			name: '',
			Icategory: '',
			mainImg: coverImage,
			video: '',
			description: '',
			highlight: '',
			price: null,
			subCategory: '',
			quantity: null,
			type: 'item',
			whatInsideTheBox: '',
			color: 'red',
			imgCollection: images,
			discount: 0,
			tags: [],
			success: false,
			msg: false,
		},

		validate: (values) => {
			const isValidURL = (str: string) => {
				try {
					new URL(str)
					return true
				} catch (_) {
					return false
				}
			}
			if (active === 0) {
				return {
					name:
						values.name.trim().length <= 0 ? 'Name should not be empty' : null,
					imgCollection:
						values.imgCollection.length <= 0
							? 'Images should not be empty'
							: null,
					Icategory:
						values.Icategory.length <= 0
							? 'Category name should not be empty'
							: null,
					subCategory:
						values.subCategory.length <= 0
							? 'Category name should not be empty'
							: null,
					video:
						values.video.trim().length <= 0
							? 'Video link should not be empty'
							: !isValidURL(values.video.trim())
							? 'Video link should be a valid URL'
							: null,
				}
			}

			if (active === 1) {
				return {
					description:
						values.description.trim().length <= 0
							? 'Description should not be empty'
							: null,
					highlight:
						values.highlight.trim().length <= 0
							? 'Description should not be empty'
							: null,
				}
			}
			if (active === 2) {
				return {
					price: !values.price ? 'Price should not be empty' : null,
					quantity: !values.quantity ? 'Quantity should not be empty' : null,
					// type: values.type.length <= 0 ? 'Description should not be empty' : null,
					// color: values.color.length <= 0 ? 'Color should not be empty' : null,
					whatInsideTheBox:
						values.whatInsideTheBox.length <= 0
							? 'this field should not be empty'
							: null,
					// discount: !values.discount ? 'Discount should not be empty' : null,
					// tags: values.tags.length <= 0 ? 'Food tags should not be empty' : null,
				}
			}

			return {}
		},
	})

	const nextStep = () => {
		setActive((current) => {
			const validation = form.validate()

			if (validation.hasErrors) {
				if (validation.errors?.imgCollection) {
					notificationSystem('Images should not be empty', '', true)
				}
				return current
			}

			if (coverImage.length <= 0) {
				notificationSystem('Images should not be empty', '', true)
				return current
			}

			if (current === 2 && form.values.price && form.values.quantity) {
				const {
					name,
					price,
					quantity,
					Icategory,
					subCategory,
					description,
					highlight,
					discount,
					color,
					whatInsideTheBox,
					tags,
					type,
					video,
					imgCollection,
				} = form.values

				console.log('from values', form.values)

				const formData = new FormData()
				formData.append('productName', name)
				formData.append('productPrice', price.toString())
				formData.append('quantity', quantity.toString())
				formData.append('category', Icategory)
				formData.append('subCategory', subCategory)
				formData.append('description', description)
				formData.append('highlights', JSON.stringify(highlight))
				formData.append('discount', discount.toString())
				formData.append('color', color)
				formData.append('whatInsideTheBox', whatInsideTheBox)
				formData.append('foodTags', JSON.stringify(tags))
				formData.append('productType', type)
				formData.append('videos', JSON.stringify(video))
				formData.append('businessId', id || '')
				formData.append('productImage', coverImage[0])

				if (imgCollection) {
					for (let i = 0; i < imgCollection.length; i++) {
						formData.append('images', imgCollection[i])
					}
				}

				// imgCollection.forEach((img, index) => {
				//     formData.append(`images[${index}]`, img);
				// });

				// formData.forEach((value, key) => {
				//     console.log(`form data values ${key}: ${value.toString()}`);
				// });

				setLoading(true)
				axiosInstance
					.post<IAddProductResponse>('business/add-product', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((value) => {
						setLoading(false)
						if (value.data.success) {
							setActive((prev) => (prev < 3 ? prev + 1 : prev))
						} else {
							notificationSystem('Failed', 'Something went wrong', true)
							return current
						}
					})
					.catch((error) => {
						console.error('errors', error)
						notificationSystem('Failed', 'Something went wrong', true)
						setLoading(false)
					})

				return current
			}

			return current < 3 ? current + 1 : current
		})
	}

	const prevStep = () =>
		setActive((current) => (current > 0 ? current - 1 : current))

	return (
		<Flex p={isMobile ? 'md' : 'lg'} direction='column' gap='sm'>
			{/*<Breadcrumbs separator={<IconChevronRight color={Colors.primaryColor} size="20px"/>}>{items}</Breadcrumbs>*/}
			<Title fw={600} order={3}>
				Add New Product
			</Title>
			<Stepper
				active={active}
				size={isLaptop ? 'xs' : 'sm'}
				color='#20C375'
				styles={{
					separator: {
						marginLeft: isLaptop ? rem(10) : rem(20),
						marginRight: isLaptop ? rem(10) : rem(20),
						height: rem(6),
						borderRadius: rem(10),
					},
				}}
			>
				<Stepper.Step label='Basic Information'>
					<AddBasicInfo
						form={form}
						setFile={setImages}
						images={form.values.imgCollection}
						setCoverImage={setCoverImage}
						coverImage={coverImage[0]}
					/>
				</Stepper.Step>

				<Stepper.Step label='Product Description'>
					<AddProductDescription form={form} />
				</Stepper.Step>

				<Stepper.Step label='Product Specification'>
					<AddProductSpecification form={form} />
				</Stepper.Step>
				<Stepper.Completed>
					{/*Completed! Form values:*/}
					{/*<Code block mt="xl">*/}
					{/*    {JSON.stringify(form.values, null, 2)}*/}
					{/*</Code>*/}
					<Flex h='100%' justify='center'>
						<Card w={isMobile ? '100%' : '40%'} h={isMobile ? '60vh' : '50vh'}>
							<Flex direction='column' gap='sm' align='center'>
								<Box h='250px' w='240px'>
									<Image src={orderComplete} h='230px' w='230px' />
								</Box>
								<Title>Congratulations!</Title>
								<Text c='#767C8C' ta='center'>
									Your product has been added Successfully.
								</Text>
								{/*<Button w="100%" radius="xl" color={Colors.primaryColor}*/}
								{/*        onClick={() => navigate("/seller/add-product")}>Add*/}
								{/*    another products</Button>*/}
								<Button
									size='lg'
									variant='outline'
									radius='xl'
									color={Colors.primaryColor}
									onClick={() => navigate('/seller/businesses')}
								>
									Back to Business
								</Button>
							</Flex>
						</Card>
					</Flex>
				</Stepper.Completed>
			</Stepper>

			<Group justify='flex-end' mt='xl'>
				{active !== 0 && active !== 3 && (
					<Button
						component='a'
						fw={200}
						size={isLaptop ? 'md' : 'lg'}
						color={Colors.primaryColor}
						radius='xl'
						variant='outline'
						onClick={prevStep}
					>
						Back
					</Button>
				)}
				{active !== 3 && (
					<Button
						component='a'
						size={isLaptop ? 'md' : 'lg'}
						fw={200}
						loading={loading}
						radius='xl'
						color={Colors.primaryColor}
						onClick={nextStep}
					>
						Next step
					</Button>
				)}
			</Group>
		</Flex>
	)
}

export default AddProduct
