import { em, Flex, Image, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import orderProcess from '../../../assets/orer.png'
import fallbackImage from '../../../assets/Placholder-01.png'

type OrderIProgressProps = {
	success: boolean
	message: string
}

const OrderInProgress = ({ success, message }: OrderIProgressProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	return (
		<Flex direction='column' align='center' gap='lg'>
			<Image
				src={
					success
						? orderProcess
						: 'https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/hybhpuwyakdwpdwuyc2l.png'
				}
				fallbackSrc={fallbackImage}
				w='200px'
				h='200px'
			/>
			<Text size={isMobile ? '15px' : '20px'}>
				{success ? 'Order has been placed' : message}
			</Text>
			{success && (
				<span
					style={{
						fontWeight: 400,
						color: '#A8AEBF',
					}}
				>
					A verification email has been sent to your email address
				</span>
			)}
			{/*<Group>*/}
			{/*    <Button onClick={onMoreFoodClick} size={isMobile ? "sm" : "lg"} color={Colors.primaryColor}>Order more*/}
			{/*        products</Button>*/}
			{/*    <Button onClick={onViewClick} size={isMobile ? "sm" : "lg"} color={Colors.primaryColor} variant="outline">View*/}
			{/*        receipt</Button>*/}
			{/*</Group>*/}
		</Flex>
	)
}

export default OrderInProgress
