import { Button, em, Flex, PasswordInput, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { IResetPassword } from '../../interfaces/account/IAccount.ts'
import { resetPasswordAction } from '../../store/account/accountActions.ts'
import { useAppDispatch, useAppSelector } from '../../store/store.ts'
import { getShortToken } from '../../utils/storage.ts'
import CenterLayout from './CenterLayout.tsx'
import Colors from '../../constants/colors.ts'

const ResetPassword = () => {
	const navigate = useNavigate()
	// const location = useLocation()
	const dispatch = useAppDispatch()
	const { loaders } = useAppSelector((state) => state.account)
	const shortToken = getShortToken()
	const isMobile = useMediaQuery(`(max-width: ${em(576)})`)
	const form = useForm({
		initialValues: {
			password: '',
			confirmPassword: '',
		},

		validate: {
			confirmPassword: (value, values) =>
				value !== values.password ? 'Passwords did not match' : null,
		},
	})
	return (
		<CenterLayout>
			<Flex
				my={20}
				direction='column'
				mb={isMobile ? 'lg' : ''}
				justify='center'
				align={isMobile ? 'start' : 'center'}
			>
				<Title fw={500} order={isMobile ? 3 : 1}>
					Create a new Password
				</Title>
				<Text ta={'center'}>
					Your new password must be different from previous used passwords.
				</Text>
			</Flex>
			<form
				onSubmit={form.onSubmit((values) => {
					const form: IResetPassword = {
						newPassword: values.confirmPassword,
						token: shortToken || '',
					}

					dispatch(resetPasswordAction(form)).then((data) => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-expect-error
						if (data.payload.success) {
							navigate('/sign-in')
						}
					})
				})}
			>
				<PasswordInput
					size={isMobile ? 'md' : 'lg'}
					radius={isMobile ? 'md' : 'lg'}
					withAsterisk
					label='Password'
					w='100%'
					placeholder='******'
					type='password'
					{...form.getInputProps('password')}
				/>
				<PasswordInput
					size={isMobile ? 'md' : 'lg'}
					radius={isMobile ? 'md' : 'lg'}
					withAsterisk
					label='Confirm Password'
					w='100%'
					placeholder='*******'
					type='password'
					{...form.getInputProps('confirmPassword')}
				/>
				<Button
					radius='xl'
					loading={loaders.passwordResetting}
					type='submit'
					size='lg'
					color={Colors.primaryColor}
					w='100%'
					mt='lg'
				>
					Login
				</Button>
			</form>
		</CenterLayout>
	)
}

export default ResetPassword
