import { Box, Card, em, Flex, Image, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { TopCagtory } from '../interfaces/buyer/product/IProductResponse.ts'
import Colors from '../constants/colors.ts'

type CategoryCardProps = {
	category: TopCagtory
}

const CategoryCard = ({ category }: CategoryCardProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const navigate = useNavigate()

	return (
		<Flex
			h={isMobile ? '110px' : '160px'}
			w={isMobile ? 120 : 170}
			my='lg'
			align='center'
			justify='space-between'
			pos='relative'
			direction='column'
			style={{ cursor: 'pointer' }}
			onClick={() => {
				const params = encodeURIComponent(category.name)
				navigate(`/category?category=${params}`)
			}}
		>
			<Box style={{ zIndex: 2 }} p='sm' w={isMobile ? 100 : 150}>
				<Image right={15} h={isMobile ? 70 : 125} src={category.image} />
			</Box>
			<Card
				radius='md'
				component='a'
				style={{ zIndex: 1 }}
				opacity={0.3}
				top={isMobile ? -10 : -5}
				bg={Colors.primaryColor}
				pos='absolute'
				h={isMobile ? 70 : 130}
				w={isMobile ? 80 : 140}
			></Card>

			<Text ta='center' size='sm' fw={600}>
				{category.name}
			</Text>
		</Flex>
	)
}

export default CategoryCard
