import {
	Badge,
	Button,
	Flex,
	Modal,
	Table,
	Text,
	Textarea,
	TextInput,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import Colors from '../../../../constants/colors'

const elements = [
	{
		complaintNo: '#34991',
		complaintType: 'Payment Issue',
		orderNo: '789456',
		orderDate: '12 Dec,2023',
		status: 'Acknowledged',
	},
]
const Complaint = () => {
	const [opened, { open, close }] = useDisclosure(false)

	const rows = elements.map((element) => (
		<Table.Tr key={element.complaintNo}>
			<Table.Td>{element.complaintNo}</Table.Td>
			<Table.Td>{element.complaintType}</Table.Td>
			<Table.Td>{element.orderNo}</Table.Td>
			<Table.Td>{element.orderDate}</Table.Td>
			<Table.Td>
				<Badge variant='light'>{element.status}</Badge>
			</Table.Td>
		</Table.Tr>
	))
	return (
		<>
			<Flex
				my='lg'
				w='100%'
				direction='row'
				justify='space-between'
				align='center'
			>
				<Text fw={700} size='24px' my='lg'>
					Complaint
				</Text>
				<Button color={Colors.primaryColor} size='lg' onClick={open}>
					Add
				</Button>
			</Flex>
			<Table highlightOnHover my='lg' verticalSpacing='lg'>
				<Table.Thead bg='#F5F6FA'>
					<Table.Tr>
						<Table.Th>Complaint No.</Table.Th>
						<Table.Th>Complaint Type</Table.Th>
						<Table.Th>Order No</Table.Th>
						<Table.Th>Order Date</Table.Th>
						<Table.Th>Status</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>{rows}</Table.Tbody>
			</Table>
			{/* add complaint modal */}
			<Modal
				opened={opened}
				onClose={close}
				centered
				title='Add Complaint'
				size='xl'
			>
				<Flex direction='column' gap='lg'>
					<Flex w='100%' justify='space-between'>
						<TextInput
							w='45%'
							label='Phone Number'
							placeholder='Enter phone number'
							size='lg'
							radius='sm'
						/>
						<TextInput
							w='45%'
							label='Email Address'
							placeholder='Enter email address'
							size='lg'
						/>
					</Flex>
					<Flex w='100%' justify='space-between'>
						<Textarea
							w='100%'
							label='Message'
							placeholder='Thank you in advance for taking the time to address this matter.'
						/>
					</Flex>
					<Flex justify='end'>
						<Button color={Colors.primaryColor} size='lg' onClick={close}>
							Submit
						</Button>
					</Flex>
				</Flex>
			</Modal>
		</>
	)
}

export default Complaint
