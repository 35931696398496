import { createBrowserRouter } from 'react-router-dom'
import AboutUs from './components/AboutUs.tsx'
import { BuyerNavbar } from './components/buyer-navbar/BuyerNavbar.tsx'
import { Navbar } from './components/navbar/Navbar.tsx'
import { SimpleNavbar } from './components/navbar/SimpleNavbar.tsx'
import { SellerNavbar } from './components/seller-navbar/SellerNavbar.tsx'
import CreatePassword from './features/account/CreatePassword.tsx'
import EmailSent from './features/account/EmailSent.tsx'
import ForgotPassword from './features/account/ForgotPassword.tsx'
import FullName from './features/account/FullName.tsx'
import Login from './features/account/Login.tsx'
import Otp from './features/account/Otp.tsx'
import PasswordUpdated from './features/account/PasswordUpdated.tsx'
import ResetPassword from './features/account/ResetPassword.tsx'
import SignUp from './features/account/SignUp.tsx'
import SingleBusinessDetail from './features/buyer/business/SingleBusinessDetail.tsx'
import Cart from './features/buyer/cart/Cart.tsx'
import MyBag from './features/buyer/cart/MyBag.tsx'
import CategoriesList from './features/buyer/categories/CategoriesList.tsx'
import Coupons from './features/buyer/home/Coupons.tsx'
import HomePage from './features/buyer/home/HomePage.tsx'
import FavouriteProducts from './features/buyer/product/FavouriteProducts.tsx'
import ProductDetailsBuyer from './features/buyer/product/ProductDetailsBuyer.tsx'
import ProductsBySearch from './features/buyer/product/ProductsBySearch.tsx'
import ProductsFilterByCategory from './features/buyer/product/ProductsFilterByCategory.tsx'
import ProductsViewAll from './features/buyer/product/ProductsViewAll.tsx'
import MyAccount from './features/buyer/profile/account/MyAccount.tsx'
import AgeVerification from './features/buyer/profile/age-verification/AgeVerification.tsx'
import Complaint from './features/buyer/profile/complain/Complaint.tsx'
import Chat from './features/buyer/profile/inbox/Chat.tsx'
import Inbox from './features/buyer/profile/inbox/Inbox.tsx'
import Language from './features/buyer/profile/language/Language.tsx'
import MyOrders from './features/buyer/profile/orders/MyOrders.tsx'
import OrderDetails from './features/buyer/profile/orders/OrderDetails.tsx'
import UnreviewedProducts from './features/buyer/profile/orders/UnreviewedProducts.tsx'
import PaymentOptions from './features/buyer/profile/payment-options/PaymentOptions.tsx'
import DepositMoney from './features/buyer/profile/wallet/DepositMoney.tsx'
import MyWallet from './features/buyer/profile/wallet/MyWallet.tsx'
import FoodsBySearch from './features/buyer/resturants/FoodsBySearch.tsx'
import Restaurants from './features/buyer/resturants/Restaurants.tsx'
import GuestHomePage from './features/guest/GuestHomePage.tsx'
import GuestProductDetail from './features/guest/GuestProductDetail.tsx'
import GuestProductsFilterByCategory from './features/guest/GuestProductsFilterByCategory.tsx'
import GuestProductsViewAll from './features/guest/GuestProductsViewAll.tsx'
import AddNewBusiness from './features/seller/businesses/AddNewBusiness.tsx'
import MyBusinesses from './features/seller/businesses/MyBusinesses.tsx'
import UpdateBusiness from './features/seller/businesses/UpdateBusiness.tsx'
import Dashboard from './features/seller/dashboard/Dashboard.tsx'
import Notifications from './features/seller/inbox/Notifications.tsx'
import SellerChat from './features/seller/inbox/SellerChat.tsx'
import SellerInbox from './features/seller/inbox/SellerInbox.tsx'
import Orders from './features/seller/orders/Orders.tsx'
import SellerOrderDetails from './features/seller/orders/SellerOrderDetails.tsx'
import AddProduct from './features/seller/products/AddProduct.tsx'
import ProductDetails from './features/seller/products/ProductDetails.tsx'
import Products from './features/seller/products/Products.tsx'
import SellerProfile from './features/seller/seller-profile/SellerProfile.tsx'
import SellerFoodShop from './features/seller/SellerFoodShop.tsx'
import ViewAllFood from './features/seller/ViewAllFood.tsx'
import SellerWallet from './features/seller/wallet/SellerWallet.tsx'
import ContactUs from './features/terms-policy/ContactUs.tsx'
import FAQs from './features/terms-policy/FAQs.tsx'
import PrivacyPolicy from './features/terms-policy/PrivacyPolicy.tsx'
import TermsAndConditions from './features/terms-policy/TermsAndConditions.tsx'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Navbar />,
		children: [
			{
				path: '',
				element: <GuestHomePage />,
			},
			{
				path: 'product/:id',
				element: <GuestProductDetail />,
			},
			{
				path: 'guest-category',
				element: <GuestProductsFilterByCategory />,
			},
			{
				path: 'name-info',
				element: <FullName />,
			},
			// {
			//     path: "person-address",
			//     element: <PersonAddress/>
			// },
			{
				path: 'create-password',
				element: <CreatePassword />,
			},

			{
				path: 'check-email',
				element: <EmailSent />,
			},

			{
				path: 'view-all',
				element: <GuestProductsViewAll />,
			},
			{
				path: 'sellers-food-shop/:id',
				element: <SellerFoodShop />,
			},
			{
				path: 'view-all-foods/:id',
				element: <ViewAllFood />,
			},
			{
				path: 'search-products',
				element: <ProductsBySearch />,
			},
			{
				path: 'search-food',
				element: <FoodsBySearch />,
			},

			{
				path: 'faqs',
				element: <FAQs />,
			},
		],
	},
	{
		path: '/sign-in',
		element: <Login />,
	},
	{
		path: '/sign-up',
		element: <SignUp />,
	},
	{
		path: '/otp',
		element: <Otp />,
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />,
	},
	{
		path: '/reset-password',
		element: <ResetPassword />,
	},
	{
		path: '/password-updated',
		element: <PasswordUpdated />,
	},
	{
		path: '/',
		element: <BuyerNavbar />,
		children: [
			{
				path: 'buyer',
				element: <HomePage />,
			},
			{
				path: 'products/:id',
				element: <ProductDetailsBuyer />,
			},
			{
				path: 'coupons',
				element: <Coupons />,
			},
			{
				path: 'order-detail/:id',
				element: <OrderDetails />,
			},
			{
				path: 'category',
				element: <ProductsFilterByCategory />,
			},
			{
				path: 'view-all-buyer',
				element: <ProductsViewAll />,
			},
			{
				path: 'favourite-products',
				element: <FavouriteProducts />,
			},
			{
				path: 'search-products-buyer',
				element: <ProductsBySearch />,
			},
			{
				path: 'search-food-buyer',
				element: <FoodsBySearch />,
			},
			{
				path: 'restaurants',
				element: <Restaurants />,
			},
			{
				path: 'payment',
				element: <Cart />,
			},
			{
				path: 'my-bag',
				element: <MyBag />,
			},
			{
				path: 'sellers-food-shop-buyer/:id',
				element: <SellerFoodShop />,
			},
			{
				path: 'buyer-view-all-foods/:id',
				element: <ViewAllFood />,
			},
			{
				path: 'my-account',
				element: <MyAccount />,
			},
			{
				path: 'my-orders',
				element: <MyOrders />,
			},
			{
				path: 'my-wallet',
				element: <MyWallet />,
			},
			{
				path: 'deposit-money',
				element: <DepositMoney />,
			},
			{
				path: 'payment-option',
				element: <PaymentOptions />,
			},
			{
				path: 'inbox',
				element: <Inbox />,
			},
			{
				path: 'complaints',
				element: <Complaint />,
			},
			{
				path: 'language',
				element: <Language />,
			},
			{
				path: 'age-verification',
				element: <AgeVerification />,
			},
			{
				path: 'chat/:id',
				element: <Chat />,
			},
			{
				path: 'business-details',
				element: <SingleBusinessDetail />,
			},
			{
				path: 'all-categories',
				element: <CategoriesList />,
			},
			{
				path: 'notification',
				element: <Notifications />,
			},
			{
				path: 'contact-us-buyer',
				element: <ContactUs />,
			},
			{
				path: 'faqs-buyer',
				element: <FAQs />,
			},

			{
				path: 'review-products',
				element: <UnreviewedProducts />,
			},
		],
	},

	{
		path: '/seller',
		element: <SellerNavbar />,
		children: [
			{
				path: '',
				element: <Dashboard />,
			},
			{
				path: 'seller-profile',
				element: <SellerProfile />,
			},
			{
				path: 'businesses',
				element: <MyBusinesses />,
			},
			{
				path: 'new-business',
				element: <AddNewBusiness />,
			},
			{
				path: 'update-business/:id',
				element: <UpdateBusiness />,
			},
			{
				path: 'products/:id',
				element: <Products />,
			},
			{
				path: 'product-detail',
				element: <ProductDetails />,
			},
			{
				path: 'add-product/:id',
				element: <AddProduct />,
			},
			{
				path: 'orders',
				element: <Orders />,
			},
			{
				path: 'order-details/:id',
				element: <SellerOrderDetails />,
			},
			{
				path: 'seller-wallet',
				element: <SellerWallet />,
			},
			{
				path: 'seller-inbox',
				element: <SellerInbox />,
			},
			{
				path: 'chat/:id',
				element: <SellerChat />,
			},
			{
				path: 'notification',
				element: <Notifications />,
			},
			{
				path: 'contact-us',
				element: <ContactUs />,
			},
			{
				path: 'faqs',
				element: <ContactUs />,
			},
		],
	},
	{
		path: '/',
		element: <SimpleNavbar />,
		children: [
			{
				path: '/terms-and-conditions',
				element: <TermsAndConditions />,
			},
			{
				path: '/privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: 'contact-us',
				element: <ContactUs />,
			},
			{
				path: 'about-us',
				element: <AboutUs />,
			},
		],
	},
])

export default router
