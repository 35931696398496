import {
	Accordion,
	Box,
	Button,
	Card,
	Container,
	em,
	Flex,
	Group,
	Image,
	NavLink,
	Overlay,
	Text,
	Textarea,
	TextInput,
	Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
	GoogleMap as GMap,
	GoogleMap,
	Libraries,
	useLoadScript,
} from '@react-google-maps/api'
import {
	IconLocation,
	IconMessage,
	IconPhoneFilled,
	IconPlus,
} from '@tabler/icons-react'
import { useState } from 'react'
import {
	buyerFaqs,
	driverFaqs,
	privacyFaqs,
	sellersFaqs,
	termsAndCondition,
} from '../../utils/faqData'
import classes from './ContactUs.module.css'
import { data } from './FAQs'

const libraries: Libraries = ['places'] // Enable search functionality

const center = {
	lat: 37.7749, // Default latitude (San Francisco)
	lng: -122.4194, // Default longitude (San Francisco)
}

const ContactUs = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const [faqItem, setFaqItem] = useState('buyer')

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: 'AIzaSyCNBB_5k5rFh4EJuZSDCePdyM7Mhw7_R8M', // Replace with your API key
		libraries,
	})

	const items = data.map((item, index) => (
		<NavLink
			key={index}
			active={item.label === faqItem}
			label={item.description}
			onClick={() => setFaqItem(item.label)}
			variant='filled'
		/>
	))

	const buyersFaqItems = buyerFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const sellersFaqItems = sellersFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const driverFaqItems = driverFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const privacyFaqItems = privacyFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const termsAndConditionsFaqItems = termsAndCondition.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	return (
		<Flex direction='column' w='100%' justify='center' align='center'>
			<div className={classes.hero}>
				<Overlay
					gradient='linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)'
					opacity={1}
					zIndex={0}
				/>
				<Container className={classes.container} size='md'>
					<Title c={'white'} className={classes.title}>
						Contact Us
					</Title>
					<Text size='sm' mt={'xl'} className={classes.description}>
						Have any questions or need assistance? We're here to help – reach
						out to us, and we'll get back to you as soon as possible!
					</Text>

					<Button
						variant='gradient'
						size='xl'
						radius='xl'
						className={classes.control}
					>
						Buy Now
					</Button>
				</Container>
			</div>
			<Flex className={classes.inner} w={'100%'} gap={100}>
				<Box w={isMobile ? '100%' : 580}>
					<Image
						w={'100%'}
						style={{
							borderRadius: '19px',
						}}
						src={'https://leadsoftwares.com/assets/contact1-CT8yvFTx.jpg'}
						className={classes.image}
					/>
				</Box>
				<div className={classes.content}>
					<Title className={classes.title}>
						A <span className={classes.highlight}>modern</span> React <br />{' '}
						components library
					</Title>
					<Text c='dimmed' mt='md' w={isMobile ? '100%' : 300}>
						Build fully functional accessible web applications faster than ever
						– Mantine includes more than 120 customizable components and hooks
						to cover you in any situation
					</Text>
				</div>
			</Flex>
			<Flex
				w={'100%'}
				direction={isMobile ? 'column' : 'row'}
				gap={40}
				align={'start'}
				my={isMobile ? 10 : 90}
				justify={'center'}
				p={isMobile ? 15 : 0}
			>
				<Box w={isMobile ? '100%' : '40%'}>
					{isLoaded ? (
						<GoogleMap
							mapContainerStyle={{ height: '400px', borderRadius: '10px' }}
						>
							<GMap
								mapContainerStyle={{ height: '100%' }}
								zoom={8}
								center={center}
							></GMap>
						</GoogleMap>
					) : (
						<div>Loading...</div>
					)}
				</Box>
				<Flex direction={'column'} gap={25} w={isMobile ? '100%' : '30%'}>
					<TextInput
						label='Name'
						placeholder='John Doe'
						size='xl'
						radius={'md'}
						mt='md'
						classNames={{ input: classes.input, label: classes.inputLabel }}
					/>
					<TextInput
						label='Email'
						placeholder='your@email.com'
						size='xl'
						radius={'md'}
						required
						classNames={{ input: classes.input, label: classes.inputLabel }}
					/>
					<Textarea
						required
						label='Your message'
						placeholder='I want to order your goods'
						rows={4}
						size='xl'
						radius={'md'}
						mt='md'
						classNames={{ input: classes.input, label: classes.inputLabel }}
					/>

					<Group justify='flex-end' mt='md'>
						<Button size='lg' className={classes.control}>
							Send message
						</Button>
					</Group>
				</Flex>
			</Flex>
			<Container size='lg' mt={120} py='xl'>
				<Title order={3} className={classes.title} ta='center' mt='sm'>
					Contact with us
				</Title>

				<Flex
					gap={40}
					direction={isMobile ? 'column' : 'row'}
					justify={'center'}
					my={30}
				>
					<Card
						w={isMobile ? '100%' : 520}
						shadow='md'
						radius='md'
						className={classes.card}
						padding='xl'
					>
						<Flex w={'100%'} justify={'center'}>
							<IconLocation />
						</Flex>
						<Text
							fz='lg'
							ta={'center'}
							fw={500}
							className={classes.cardTitle}
							mt='md'
						>
							Location
						</Text>
						<Text ta={'center'} fz='sm' c='dimmed' mt='sm'>
							Find us
						</Text>
						<Text ta={'center'} fz={'md'} c={'dark'} mt={'sm'}>
							Florida USA
						</Text>
					</Card>
					<Card
						w={isMobile ? '100%' : 520}
						shadow='md'
						radius='md'
						className={classes.card}
						padding='xl'
					>
						<Flex w={'100%'} justify={'center'}>
							<IconPhoneFilled />
						</Flex>
						<Text
							fz='lg'
							ta={'center'}
							fw={500}
							className={classes.cardTitle}
							mt='md'
						>
							Phone
						</Text>
						<Text ta={'center'} fz='sm' c='dimmed' mt='sm'>
							Reach out to us by phone
						</Text>
						<Text ta={'center'} fz={'md'} c={'dark'} mt={'sm'}>
							+92 316 6072132
						</Text>
					</Card>
					<Card
						w={isMobile ? '100%' : 520}
						shadow='md'
						radius='md'
						className={classes.card}
						padding='xl'
					>
						<Flex w={'100%'} justify={'center'}>
							<IconMessage />
						</Flex>
						<Text
							fz='lg'
							ta={'center'}
							fw={500}
							className={classes.cardTitle}
							mt='md'
						>
							Email
						</Text>
						<Text ta={'center'} fz='sm' c='dimmed' mt='sm'>
							Contact us at
						</Text>
						<Text ta={'center'} fz={'md'} c={'dark'} mt={'sm'}>
							contact@tigerit.com
						</Text>
					</Card>
				</Flex>
			</Container>
			<Flex
				w={isMobile ? '100%' : '70%'}
				my='40px'
				direction={isMobile ? 'column' : 'row'}
				p='sm'
				gap='lg'
			>
				<Flex w={isMobile ? '100%' : '50%'} direction='column' justify='center'>
					<Card radius='md' bg='#F8F9FA'>
						{items}
					</Card>
				</Flex>
				<Flex w={isMobile ? '100%' : '50%'} direction='column'>
					<Text size='30px' mb='xs'>
						FAQs
					</Text>
					<Accordion
						mt='lg'
						radius='md'
						transitionDuration={500}
						chevronPosition='left'
						classNames={{ chevron: classes.chevron }}
						chevron={<IconPlus className={classes.icon} />}
						variant='separated'
						defaultValue='Apples'
					>
						{faqItem === 'buyer'
							? buyersFaqItems
							: faqItem === 'seller'
							? sellersFaqItems
							: faqItem === 'driver'
							? driverFaqItems
							: faqItem === 'privacy'
							? privacyFaqItems
							: termsAndConditionsFaqItems}
					</Accordion>
				</Flex>
			</Flex>
		</Flex>
	)
}

export default ContactUs
