import '@mantine/carousel/styles.css'
import '@mantine/charts/styles.css'
import {
	Button,
	createTheme,
	MantineProvider,
	PasswordInput,
	PillsInput,
	rem,
	Select,
	TextInput,
} from '@mantine/core'
import '@mantine/core/styles.css'
import { DatePickerInput } from '@mantine/dates'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import '@mantine/tiptap/styles.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import classes from './input.module.css'
import router from './RouterProvider.tsx'
import store from './store/store.ts'

const theme = createTheme({
	fontFamily: 'Lexend',
	headings: { fontFamily: 'Lexend' },
	breakpoints: {
		xs: '30em',
		sm: '48em',
		md: '64em',
		lg: '74em',
		xl: '90em',
	},
	primaryColor: 'lime-green',
	colors: {
		'lime-green': [
			'#eff9e7',
			'#e0f2d0',
			'#d0ecb8',
			'#c0e5a0',
			'#b1df89',
			'#a1d871',
			'#91d259',
			'#81cb41',
			'#72c52a',
			'#62BE12',
		],
	},
	components: {
		TextInput: TextInput.extend({
			classNames: { label: classes.label, input: classes.input },
		}),
		Select: Select.extend({
			classNames: { label: classes.label, input: classes.input },
		}),
		DatePickerInput: DatePickerInput.extend({
			classNames: { label: classes.label, input: classes.input },
		}),
		PillsInput: PillsInput.extend({
			classNames: { label: classes.label, input: classes.input },
		}),
		PasswordInput: PasswordInput.extend({
			classNames: { label: classes.label, input: classes.input },
		}),
		Button: Button.extend({
			vars: (_, props) => {
				if (props.size === 'xxl') {
					return {
						root: {
							'--button-height': rem(55),
							// '--button-padding-x': rem(30),
							// '--button-fz': rem(24),
						},
					}
				}

				if (props.size === 'xxs') {
					return {
						root: {
							'--button-height': rem(24),
							'--button-padding-x': rem(10),
							'--button-fz': rem(10),
						},
					}
				}

				return { root: {} }
			},
		}),
	},
})

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<Provider store={store}>
			<MantineProvider theme={theme}>
				<ModalsProvider>
					<Notifications />
					<RouterProvider router={router} />
				</ModalsProvider>
			</MantineProvider>
		</Provider>
	</React.StrictMode>
)
