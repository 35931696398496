import { Button, Flex, Group, rem, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { UseFormReturnType } from '@mantine/form'
import { IconUpload, IconX } from '@tabler/icons-react'
import { useRef } from 'react'
import { IProductAdd } from '../interfaces/business/seller/IProductCreate.ts'
import Colors from '../constants/colors.ts'

type DragZoneProps = {
	images?: File[]
	setFile: (file: File[]) => void
	form?: UseFormReturnType<IProductAdd, (values: IProductAdd) => IProductAdd>
}

const DragZone = ({ setFile, images, form }: DragZoneProps) => {
	const openRef = useRef<() => void>(null)
	return (
		<Dropzone
			openRef={openRef}
			onDrop={(files) => {
				if (images && images?.length > 0) {
					console.log('images in images true', files)
					const newArr = [...images, ...files]
					if (newArr) {
						setFile(newArr)
						form?.setValues({ imgCollection: newArr })
					}
				} else {
					console.log('images in images false', files)
					if (form?.values?.imgCollection) {
						const arr = [...form?.values?.imgCollection, ...files]
						form?.setValues({ imgCollection: arr })
					}
				}
			}}
			onReject={(files) => console.log('rejected files', files)}
			maxSize={5 * 1024 ** 2}
			accept={IMAGE_MIME_TYPE}
		>
			<Group
				justify='center'
				gap='xl'
				mih={220}
				style={{ pointerEvents: 'none' }}
			>
				<Dropzone.Accept>
					<IconUpload
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-blue-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-red-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Reject>

				<Flex direction='column' align='center' gap='sm'>
					<Text size='xl' inline>
						Drag & Drop files here to upload
					</Text>
					<Button
						component='a'
						fw={200}
						color={Colors.primaryColor}
						variant='outline'
						radius='xl'
						onClick={() => openRef.current?.()}
					>
						{' '}
						Choose Files
					</Button>
				</Flex>
			</Group>
		</Dropzone>
	)
}

export default DragZone
