import { Alert, Button, Card, em, Flex, Group, Title } from '@mantine/core'
import { FileWithPath } from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import { useMediaQuery } from '@mantine/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomDropzone from '../../../../components/CustomDropzone.tsx'
import { ageVerificationAction } from '../../../../store/account/accountActions.ts'
import { useAppDispatch } from '../../../../store/store.ts'
import Colors from '../../../../constants/colors.ts'

const AgeVerification = () => {
	const [frontPage, setFrontPage] = useState<
		File | null | FileWithPath | string
	>(null)
	const [backPage, setBackPage] = useState<File | null | FileWithPath | string>(
		null
	)
	const [disable, setDisable] = useState(false)
	const [loading, setLoading] = useState(false)
	const [frontImageUploading, setFrontImageUploading] = useState(false)
	const [backImageUploading, setBackImageUploading] = useState(false)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const form = useForm({
		initialValues: {
			firstName: '',
			lastName: '',
			age: '',
			sex: '',
			front: '',
			back: '',
		},

		validate: {
			front: (values) =>
				values.length <= 0 ? 'Images should not be empty' : null,
			back: (values) =>
				values.length <= 0 ? 'Images should not be empty' : null,
		},
	})

	const nextStep = () => {
		if (form.validate().hasErrors) {
			setDisable(!disable)
		} else {
			setDisable(false)
			setLoading(true)
			dispatch(
				ageVerificationAction({
					front: form.values.front,
					back: form.values.back,
				})
			).then((value) => {
				if (value) {
					navigate('/my-account', { state: { verification: 'pending' } })

					setLoading(false)
				}
			})
			setLoading(false)
		}
	}

	const prevStep = () => navigate(-1)
	return (
		<Flex direction='column'>
			<Card w='100%' pos='fixed' style={{ zIndex: '100' }}>
				<Flex w='100%' justify='center'>
					<Flex
						justify='space-between'
						align='center'
						w={isMobile ? '100%' : 1000}
					>
						<Title order={isMobile ? 4 : 3}>Verify your age</Title>
						{/*<Button radius="xl" color={Colors.primaryColor}>Create Coupon</Button>*/}
					</Flex>
				</Flex>
			</Card>
			<Flex direction='column' justify='center' align='center' mt={60}>
				<Flex direction='column' w={isMobile ? '100%' : 1040} p='md'>
					<Flex direction='column' gap='sm'>
						<CustomDropzone
							loading={frontImageUploading}
							label='Upload Front Side'
							onDrop={async (file) => {
								setFrontImageUploading(true)
								const imgStr = URL.createObjectURL(file[0]) // Convert file to data URL
								setFrontPage(imgStr) // Update local state for the preview URL if needed
								form.setValues((prevState) => ({ ...prevState, front: imgStr })) // Set form value
								setFrontImageUploading(false)
							}}
							url={frontPage}
						/>
						<CustomDropzone
							loading={backImageUploading}
							label='Upload back Side'
							onDrop={async (file) => {
								setBackImageUploading(true)
								const imgStr = URL.createObjectURL(file[0]) // Convert file to data URL
								setBackPage(imgStr) // Update local state for the preview URL if needed
								form.setValues((prevState) => ({ ...prevState, back: imgStr })) // Set form value
								setBackImageUploading(false)
							}}
							url={backPage}
						/>
					</Flex>

					<Group justify='flex-end' mt='xl'>
						<Button
							variant='outline'
							color={Colors.primaryColor}
							radius='xl'
							onClick={prevStep}
						>
							Cancel
						</Button>
						<Button
							loading={loading}
							color={Colors.primaryColor}
							radius='xl'
							onClick={nextStep}
						>
							Continue
						</Button>
					</Group>
					{disable && (
						<Alert
							onClose={() => setDisable(false)}
							withCloseButton
							my='sm'
							variant='light'
							color='red'
							title='Form not filled correctly'
						>
							Please fill out form properly
						</Alert>
					)}
				</Flex>
			</Flex>
		</Flex>
	)
}

export default AgeVerification
