import {
    IFavouriteProducts,
    IGuestProducts,
    IProduct,
    IProductResponse,
} from "../../interfaces/buyer/product/IProductResponse.ts";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    addToCartAction,
    addToFavouriteAction,
    createBuyNowOrderAction,
    createOrderAction,
    deleteCartAction,
    deleteCartItemAction,
    getAllCartItems,
    getAllCoupons,
    getAllFavouriteProductsAction,
    getAllGuestProductsAction,
    getAllGuestProductsActionByCategory,
    getAllProductsAction,
    getAllProductsActionByCategory,
    getAllProductsActionBySubCategory,
    getAllUnreviewedProductsAction,
    getOneGuestProductAction,
    getOneProduct,
    getProductsSuggestionAction,
    getRecentSearchesAction,
    removeFromFavouriteAction,
    searchProductsAction,
} from "./productActions.ts";
import {IProductByCategoryResponse} from "../../interfaces/buyer/product/IProductByCategoryResponse.ts";
import {IProductBySubCategoryResponse} from "../../interfaces/buyer/product/IProductBySubCategory.ts";
import {IDeleteCartItemResponse} from "../../interfaces/buyer/cart/IDeleteCartItemResponse.ts";
import {IUpdatedCartResponse} from "../../interfaces/buyer/cart/IUpdatetdCartResponse.ts";
import {ICouponsResponse} from "../../interfaces/buyer/cart/ICouponsResponse.ts";
import {IProductSearchResponse} from "../../interfaces/buyer/product/IProductSearchResponse.ts";
import {ISingleProductResponse} from "../../interfaces/buyer/product/ISingleProductResponse.ts";
import {ISuggestedProductsResponse} from "../../interfaces/buyer/product/ISuggestedProductsResponse.ts";
import {IGuestSingleProduct} from "../../interfaces/guest/IGuestSingleProduct.ts";
import {IAddToCartResponse} from "../../interfaces/buyer/cart/IAddToCartResponse.ts";
import {IRecentSearches} from "../../interfaces/buyer/product/IRecentSearches.ts";
import {IUnReviewedProductsResponse} from "../../interfaces/buyer/product/IUnReviewedProductsResponse.ts";

interface initialState {
    products: IProductResponse;
    singleProduct: ISingleProductResponse;
    guestProducts: IGuestProducts;
    favouriteProducts: IFavouriteProducts;
    allProductBySubcategory: IProductBySubCategoryResponse;
    guestProduct: IGuestSingleProduct;
    allCoupons: ICouponsResponse;
    suggestedProducts: ISuggestedProductsResponse;
    unreviewedProducts: IUnReviewedProductsResponse
    cartItems: number;
    allRecentSearches: IRecentSearches;
    loaders: {
        gettingAllProducts: boolean;
        gettingAllGuestProducts: boolean;
        gettingAllProductsWithCategory: boolean;
        gettingOneProduct: boolean;
        gettingAllCartItems: boolean;
        deletingCartItems: boolean;
        addingItemToCart: boolean;
        deletingItemFromCart: boolean;
        addingItemToFavourite: boolean;
        gettingAllFavouriteProducts: boolean;
        gettingAllProductBySubCategory: boolean;
        gettingAllCoupons: boolean;
        searchingProducts: boolean;
        gettingSuggestedProducts: boolean;
        buyNow: boolean;
        creatingOrder: boolean;
        gettingAllRecentSearches: boolean
        gettingUnreviewedProducts: boolean;
    };
    productsWithCategory: IProductByCategoryResponse;
    searchedProducts: IProductSearchResponse;
    product: { success: boolean; product: IProduct };
    cart: IUpdatedCartResponse;
    cartItemsAdded: IAddToCartResponse;
}

const initialState: initialState = {
    unreviewedProducts: {
        success: false,
        data: []
    },
    allRecentSearches: {
        success: false,
        searches: []
    },
    singleProduct: {
        success: false,
        product: {
            _id: "",
            productName: "",
            productPrice: {
                amount: 0,
                currency: "",
            },
            discount: 0,
            productImage: "",
            description: "",
            quantity: 0,
            featured: false,
            promoted: false,
            videos: [],
            images: [],
            highlights: [],
            whatInsideTheBox: "",
            color: "",
            sellerId: "",
            category: [],
            stockStatus: false,
            availabilityStatus: "",
            rating: {
                totalRating: 0,
                numRating: 0,
            },
            productType: "",
            tag: [],
            favoriteOf: [],
            businessId: "",
            updatedAt: "",
            subCategory: [],
            businessDetails: {
                businessId: "",
                businessTitle: "",
                businessType: "",
                businessImage: "",
            },
            __v: 0,
            reviews: [],
            foodTags: []
        },
    },
    allCoupons: {
        success: false,
        activeCoupons: [],
        inactiveCoupons: [],
    },
    allProductBySubcategory: {
        success: false,
        length: 0,
        products: [],
    },
    suggestedProducts: {
        success: false,
        productsFromSameShop: [],
        similarProducts: [],
        youMightAlsoLike: [],
    },
    products: {
        success: false,
        data: {
            homeSlides: [],
            categoryProducts: [],
            nearbyRestaurants: [],
            loyaltyPoints: 0,
            topCagtories: [],
        },
        ipInfo: {
            ip: "",
            city: "",
            region: "",
            country: "",
            loc: "",
            org: "",
            postal: "",
            timezone: ""
        }
    },
    guestProducts: {
        success: false,
        length: 0,
        products: [],
    },
    favouriteProducts: {
        success: false,
        length: 0,
        favorites: [],
    },
    loaders: {
        gettingAllProducts: false,
        gettingAllGuestProducts: false,
        gettingAllProductsWithCategory: false,
        gettingOneProduct: false,
        gettingAllCartItems: false,
        deletingCartItems: false,
        addingItemToCart: false,
        deletingItemFromCart: false,
        addingItemToFavourite: false,
        gettingAllFavouriteProducts: false,
        gettingAllProductBySubCategory: false,
        gettingAllCoupons: false,
        searchingProducts: false,
        gettingSuggestedProducts: false,
        buyNow: false,
        creatingOrder: false,
        gettingAllRecentSearches: false,
        gettingUnreviewedProducts: false
    },
    productsWithCategory: {
        success: false,
        subCategories: [],
    },
    searchedProducts: {
        success: false,
        length: 0,
        products: [],
    },
    product: {
        success: false,
        product: {
            _id: "",
            productName: "",
            productPrice: {
                amount: 0,
                currency: "",
            },
            discount: 0,
            productImage: "",
            description: "",
            quantity: 0,
            featured: false,
            promoted: false,
            videos: [],
            images: [],
            highlights: [],
            whatInsideTheBox: "",
            color: "",
            category: [],
            subCategory: [],
            stockStatus: false,
            availabilityStatus: "",
            rating: {
                totalRating: 0,
                numRating: 0,
            },
            productType: "",
            tag: [],
            favoriteOf: [],
            createdAt: "",
            updatedAt: "",
            __v: 0,
        },
    },
    guestProduct: {
        success: false,
        product: {
            _id: "",
            productName: "",
            productPrice: {
                amount: 0,
                currency: "",
            },
            discount: 0,
            productImage: "",
            description: "",
            quantity: 0,
            featured: false,
            promoted: false,
            videos: [],
            images: [],
            highlights: [],
            whatInsideTheBox: "",
            color: "",
            sellerId: "",
            category: [],
            stockStatus: false,
            availabilityStatus: "",
            rating: {
                totalRating: 0,
                numRating: 0,
            },
            productType: "",
            tag: [],
            businessId: {
                _id: "",
                userId: "",
                address: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "",
                    coordinates: [],
                },
                title: "",
                image: "",
                type: "",
                registrationNumber: "",
                phoneNumber: "",
                category: "",
                email: "",
                description: "",
                verificationStatus: "",
                rating: {
                    totalRating: 0,
                    numRating: 0,
                    sales: 0,
                },
                createdAt: "",
                updatedAt: "",
                __v: 0,
                businessDescription: "",
            },
            updatedAt: "",
        },
    },
    cart: {
        success: false,
        cart: {
            details: [],
            total: [],
            orderTotal: {
                price: {
                    amount: 0,
                    currency: "",
                },
                type: "",
                title: "",
            },
            totalCount: 0,
        },
    },
    cartItems: 0,
    cartItemsAdded: {
        success: false,
        cart: {
            _id: "",
            userId: "",
            items: [],
            createdAt: "",
            updatedAt: "",
            __v: 0,
        },
    },
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        toggleCartAction: (state, {payload}: PayloadAction<number>) => {
            state.cartItems = payload;
        },
        deleteCartItem: (
            state,
            {payload}: PayloadAction<IDeleteCartItemResponse>
        ) => {
            // state.cart.cart.details = state.cart.cart.details.filter((item) => item.products._id !== payload.deletedProduct._id)
            const detailIndex = state.cart.cart.details.findIndex((detail) =>
                detail.products.some(
                    (product) => product._id === payload.deletedProduct._id
                )
            );

            if (detailIndex !== -1) {
                // Find the product index within the products array
                const productIndex = state.cart.cart.details[
                    detailIndex
                    ].products.findIndex(
                    (product) => product._id === payload.deletedProduct._id
                );
                if (productIndex !== -1) {
                    // Remove the product from the products array
                    state.cart.cart.details[detailIndex].products.splice(productIndex, 1);

                    // Recalculate total
                    const totalPrice = state.cart.cart.details[
                        detailIndex
                        ].products.reduce((acc, product) => acc + product.price.amount, 0);
                    state.cart.cart.details[detailIndex].total[0].price.amount =
                        totalPrice;

                    // Update orderTotal
                    state.cart.cart.total[0].price.amount =
                        totalPrice +
                        state.cart.cart.total[1].price.amount +
                        state.cart.cart.total[2].price.amount;
                    state.cart.cart.orderTotal.price.amount =
                        state.cart.cart.total[0].price.amount;

                    // Decrement totalCount
                    state.cart.cart.totalCount--;
                }
            }
        },
        updateCart: (state, action) => {
            state.cart = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // get all products
            .addCase(getAllProductsAction.pending, (state) => {
                state.loaders.gettingAllProducts = true;
            })
            .addCase(getAllProductsAction.fulfilled, (state, action) => {
                state.loaders.gettingAllProducts = false;
                state.products = action.payload;
            })
            .addCase(getAllProductsAction.rejected, (state) => {
                state.loaders.gettingAllProducts = false;
            })
            // get one product
            .addCase(getOneProduct.pending, (state) => {
                state.loaders.gettingOneProduct = true;
            })
            .addCase(getOneProduct.fulfilled, (state, action) => {
                state.loaders.gettingOneProduct = false;
                if (!action.payload) return;
                state.singleProduct = action.payload;
            })
            .addCase(getOneProduct.rejected, (state) => {
                state.loaders.gettingOneProduct = false;
            })
            // get all products with category filter
            .addCase(getAllProductsActionByCategory.pending, (state) => {
                state.loaders.gettingAllProductsWithCategory = true;
            })
            .addCase(getAllProductsActionByCategory.fulfilled, (state, action) => {
                state.loaders.gettingAllProductsWithCategory = false;
                if (!action.payload) return;
                state.productsWithCategory = action.payload;
            })
            .addCase(getAllProductsActionByCategory.rejected, (state) => {
                state.loaders.gettingAllProductsWithCategory = false;
            })
            // get all products with category filter
            .addCase(getAllGuestProductsActionByCategory.pending, (state) => {
                state.loaders.gettingAllProductsWithCategory = true;
            })
            .addCase(
                getAllGuestProductsActionByCategory.fulfilled,
                (state, action) => {
                    state.loaders.gettingAllProductsWithCategory = false;
                    state.productsWithCategory = action.payload;
                }
            )
            .addCase(getAllGuestProductsActionByCategory.rejected, (state) => {
                state.loaders.gettingAllProductsWithCategory = false;
            })
            // get all products with category filter
            .addCase(searchProductsAction.pending, (state) => {
                state.loaders.searchingProducts = true;
            })
            .addCase(searchProductsAction.fulfilled, (state, action) => {
                state.loaders.searchingProducts = false;
                state.searchedProducts = action.payload;
            })
            .addCase(searchProductsAction.rejected, (state) => {
                state.loaders.searchingProducts = false;
            })
            // get all recent searches
            .addCase(getRecentSearchesAction.pending, (state) => {
                state.loaders.gettingAllRecentSearches = true;
            })
            .addCase(getRecentSearchesAction.fulfilled, (state, action) => {
                state.loaders.gettingAllRecentSearches = false;
                if (!action.payload) return
                state.allRecentSearches = action.payload;
            })
            .addCase(getRecentSearchesAction.rejected, (state) => {
                state.loaders.gettingAllRecentSearches = false;
            })
            // get all cart items
            .addCase(getAllCartItems.pending, (state) => {
                state.loaders.gettingAllCartItems = true;
            })
            .addCase(getAllCartItems.fulfilled, (state, action) => {
                state.loaders.gettingAllCartItems = false;
                if (!action.payload) return;
                state.cart = action.payload;
            })
            .addCase(getAllCartItems.rejected, (state) => {
                state.loaders.gettingAllCartItems = false;
            })
            // get all cart items
            .addCase(addToCartAction.pending, (state) => {
                state.loaders.addingItemToCart = true;
            })
            .addCase(addToCartAction.fulfilled, (state, action) => {
                state.loaders.addingItemToCart = false;
                if (!action.payload) return;
                state.cartItemsAdded = action.payload;
            })
            .addCase(addToCartAction.rejected, (state) => {
                state.loaders.addingItemToCart = false;
            })
            // adding item to favourite all cart items
            .addCase(addToFavouriteAction.pending, (state) => {
                state.loaders.addingItemToFavourite = true;
            })
            .addCase(addToFavouriteAction.fulfilled, (state, action) => {
                state.loaders.addingItemToFavourite = false;
                state.products.data.categoryProducts =
                    state.products.data.categoryProducts.map((product) => {
                        const index = product.products.findIndex(
                            (obj) => obj._id === action?.payload?.product._id
                        );
                        if (index !== -1 && action?.payload?.product) {
                            product.products[index] = action.payload.product;
                            return {
                                products: product.products,
                                category: product.category,
                            };
                        }
                        return {
                            products: product.products,
                            category: product.category,
                        };
                    });
            })
            .addCase(addToFavouriteAction.rejected, (state) => {
                state.loaders.addingItemToFavourite = false;
            })
            // deleting item from cart
            .addCase(deleteCartItemAction.pending, (state) => {
                state.loaders.deletingItemFromCart = true;
            })
            .addCase(deleteCartItemAction.fulfilled, (state) => {
                state.loaders.deletingItemFromCart = false;
            })
            .addCase(deleteCartItemAction.rejected, (state) => {
                state.loaders.deletingItemFromCart = false;
            })
            // delete cart
            .addCase(deleteCartAction.pending, (state) => {
                state.loaders.deletingCartItems = true;
            })
            .addCase(deleteCartAction.fulfilled, (state) => {
                state.loaders.deletingCartItems = false;
            })
            .addCase(deleteCartAction.rejected, (state) => {
                state.loaders.deletingCartItems = false;
            })
            // get all guest products
            .addCase(getAllGuestProductsAction.pending, (state) => {
                state.loaders.gettingAllProducts = true;
            })
            .addCase(getAllGuestProductsAction.fulfilled, (state, action) => {
                state.loaders.gettingAllProducts = false;
                state.guestProducts = action.payload;
            })
            .addCase(getAllGuestProductsAction.rejected, (state) => {
                state.loaders.gettingAllProducts = false;
            })
            // get all guest products
            .addCase(getAllFavouriteProductsAction.pending, (state) => {
                state.loaders.gettingAllFavouriteProducts = true;
            })
            .addCase(getAllFavouriteProductsAction.fulfilled, (state, action) => {
                state.loaders.gettingAllFavouriteProducts = false;
                state.favouriteProducts = action.payload;
            })
            .addCase(getAllFavouriteProductsAction.rejected, (state) => {
                state.loaders.gettingAllFavouriteProducts = false;
            })
            // get all guest one product
            .addCase(getOneGuestProductAction.pending, (state) => {
                state.loaders.gettingOneProduct = true;
            })
            .addCase(getOneGuestProductAction.fulfilled, (state, action) => {
                state.loaders.gettingOneProduct = false;
                if (!action.payload) return;
                state.guestProduct = action.payload;
            })
            .addCase(getOneGuestProductAction.rejected, (state) => {
                state.loaders.gettingOneProduct = false;
            })
            // get all products by sub category
            .addCase(getAllProductsActionBySubCategory.pending, (state) => {
                state.loaders.gettingAllProductBySubCategory = true;
            })
            .addCase(getAllProductsActionBySubCategory.fulfilled, (state, action) => {
                state.loaders.gettingAllProductBySubCategory = false;
                if (!action.payload) return;
                state.allProductBySubcategory = action.payload;
            })
            .addCase(getAllProductsActionBySubCategory.rejected, (state) => {
                state.loaders.gettingAllProductBySubCategory = false;
            })
            // get all suggested products
            .addCase(getProductsSuggestionAction.pending, (state) => {
                state.loaders.gettingSuggestedProducts = true;
            })
            .addCase(getProductsSuggestionAction.fulfilled, (state, action) => {
                state.loaders.gettingSuggestedProducts = false;
                if (!action.payload) return;
                state.suggestedProducts = action.payload;
            })
            .addCase(getProductsSuggestionAction.rejected, (state) => {
                state.loaders.gettingSuggestedProducts = false;
            })
            // get all remove From Favourite Action
            .addCase(removeFromFavouriteAction.pending, (state) => {
                state.loaders.gettingAllProductBySubCategory = true;
            })
            .addCase(removeFromFavouriteAction.fulfilled, (state, action) => {
                state.loaders.gettingAllProductBySubCategory = false;
                if (!action.payload) return;
                state.favouriteProducts.favorites =
                    state.favouriteProducts.favorites.filter(
                        (p) => p._id !== action?.payload?.product?._id
                    );

                function updateProductById(
                    root: IProductResponse,
                    productId: string,
                    updatedProduct: IProduct
                ): IProductResponse {
                    // Iterate through categoryProducts to find the product
                    const productIndex = root.data.categoryProducts
                        .flatMap((categoryProduct) => categoryProduct.products)
                        .findIndex((product) => product._id === productId);

                    if (productIndex !== -1) {
                        // Update the product with the new data
                        root.data.categoryProducts.forEach((categoryProduct) => {
                            categoryProduct.products[productIndex] = {
                                ...categoryProduct.products[productIndex],
                                ...updatedProduct,
                            };
                        });
                    }

                    return root;
                }

                state.products = updateProductById(
                    state?.products,
                    action.payload.product._id,
                    action.payload.product
                );
            })
            .addCase(removeFromFavouriteAction.rejected, (state) => {
                state.loaders.gettingAllProductBySubCategory = false;
            })
            // get all coupons
            .addCase(getAllCoupons.pending, (state) => {
                state.loaders.gettingAllCoupons = true;
            })
            .addCase(getAllCoupons.fulfilled, (state, action) => {
                state.loaders.gettingAllCoupons = false;
                if (!action.payload) return;
                state.allCoupons = action.payload;
            })
            .addCase(getAllCoupons.rejected, (state) => {
                state.loaders.gettingAllCoupons = false;
            })
            // buy now action for loading
            .addCase(createBuyNowOrderAction.pending, (state) => {
                state.loaders.buyNow = true;
            })
            .addCase(createBuyNowOrderAction.fulfilled, (state) => {
                state.loaders.buyNow = false;
            })
            .addCase(createBuyNowOrderAction.rejected, (state) => {
                state.loaders.buyNow = false;
            })
            // buy now action for loading
            .addCase(createOrderAction.pending, (state) => {
                state.loaders.creatingOrder = true;
            })
            .addCase(createOrderAction.fulfilled, (state) => {
                state.loaders.creatingOrder = false;
            })
            .addCase(createOrderAction.rejected, (state) => {
                state.loaders.creatingOrder = false;
            })
            // get all unreviewed products
            .addCase(getAllUnreviewedProductsAction.pending, (state) => {
                state.loaders.gettingUnreviewedProducts = true;
            })
            .addCase(getAllUnreviewedProductsAction.fulfilled, (state, action) => {
                state.loaders.gettingUnreviewedProducts = false;
                if (!action.payload) return
                state.unreviewedProducts = action.payload
            })
            .addCase(getAllUnreviewedProductsAction.rejected, (state) => {
                state.loaders.gettingUnreviewedProducts = false;
            });
    },
});

const productReducer = productSlice.reducer;
export const {toggleCartAction, deleteCartItem, updateCart} =
    productSlice.actions;
export default productReducer;
