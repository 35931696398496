import { Carousel } from '@mantine/carousel'
import { Box, Card, em, Flex, Image, rem, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import Autoplay from 'embla-carousel-autoplay'
import { ReactNode, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import fallbackImage from '../../assets/Placholder-01.png'
import Footer from '../../components/Footer.tsx'
import CategorySkeleton from '../../components/skeletons/CategorySkeleton.tsx'
import SliderSkeleton from '../../components/skeletons/SliderSkeleton.tsx'
import Colors from '../../constants/colors.ts'
import {
	HomeSlide,
	TopCagtory,
} from '../../interfaces/buyer/product/IProductResponse.ts'
import classes from './Carousal.module.css'

type HomeProps = {
	children: ReactNode
	slider?: HomeSlide[]
	topCagtories?: TopCagtory[]
	loading: boolean
}
const GuestHome = ({ children, slider, topCagtories, loading }: HomeProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)

	const autoplay = useRef(Autoplay({ delay: 2000 }))
	const navigate = useNavigate()
	return (
		<>
			<Flex direction='column' justify='space-between'>
				{/*Header Starts from here*/}
				<Flex w='100%' justify='center' visibleFrom='lg' mt='md'>
					<Carousel
						nextControlIcon={
							<IconArrowRight
								style={{
									width: rem(25),
									height: rem(25),
									background: '#F0F1F5',
									// boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
									borderRadius: '100px',
								}}
							/>
						}
						previousControlIcon={
							<IconArrowLeft
								style={{
									width: rem(25),
									height: rem(25),
									background: '#F0F1F5',
									// boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
									borderRadius: '100px',
								}}
							/>
						}
						slideSize={70}
						loop
						align='start'
						slideGap='sm'
						px={isMobile ? 55 : 80}
						mb='xl'
					>
						{loading
							? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
									<Carousel.Slide key={index}>
										<CategorySkeleton />
									</Carousel.Slide>
							  ))
							: topCagtories?.map((category, index) => {
									return (
										<Carousel.Slide key={index}>
											<Flex
												h={isMobile ? '40%' : '90px'}
												w={isMobile ? 70 : 120}
												my='sm'
												align='center'
												justify='space-between'
												pos='relative'
												key={index}
												direction='column'
												style={{ cursor: 'pointer' }}
												onClick={() => {
													const params = encodeURIComponent(category.name)
													navigate(`guest-category?category=${params}`)
												}}
											>
												<Box
													style={{ zIndex: 2 }}
													p='sm'
													w={isMobile ? 60 : 80}
												>
													<Image
														right={15}
														h={isMobile ? 50 : 55}
														src={category.image}
														fallbackSrc={fallbackImage}
													/>
												</Box>
												<Card
													radius='md'
													component='a'
													style={{ zIndex: 1 }}
													opacity={0.3}
													top={isMobile ? -10 : -5}
													bg={Colors.primaryColor}
													pos='absolute'
													h={isMobile ? 50 : 60}
													w={isMobile ? 50 : 70}
												></Card>

												<Text ta='center' size='sm' fw={isMobile ? 500 : 600}>
													{category.name}
												</Text>
											</Flex>
										</Carousel.Slide>
									)
							  })}
					</Carousel>
				</Flex>
				<Flex w='100%' justify='center'>
					<Carousel
						plugins={[autoplay.current]}
						onMouseEnter={autoplay.current.stop}
						onMouseLeave={autoplay.current.reset}
						withControls={false}
						slideSize={isMobile ? 300 : 310}
						loop
						align='start'
						withIndicators
						slideGap='sm'
						maw={isMobile ? '100%' : '75%'}
						classNames={{ indicator: classes.indicator }}
					>
						{loading
							? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
									<Carousel.Slide pr='xs' key={index}>
										<SliderSkeleton />
									</Carousel.Slide>
							  ))
							: slider?.map((img, index) => {
									return (
										<Carousel.Slide key={index}>
											<Box
												h={isMobile ? 200 : 310}
												w={isMobile ? 430 : 505}
												p='sm'
											>
												<Image
													radius='lg'
													h={isMobile ? 150 : '80%'}
													src={img.webImage}
												/>
											</Box>
										</Carousel.Slide>
									)
							  })}
					</Carousel>
				</Flex>
				<Flex justify={'center'} align='center' p={0}>
					{children}
				</Flex>
				<Footer />
			</Flex>
		</>
	)
}

export default GuestHome
