import {
	Button,
	Flex,
	Group,
	LoadingOverlay,
	SimpleGrid,
	Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BusinessCard from '../../../components/BusinessCard.tsx'
import { getAllBusinessesAction } from '../../../store/business/businessesActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import Colors from '../../../constants/colors.ts'

const MyBusinesses = () => {
	// const [approval, setApproval] = useState<string | null>("approved")
	const navigate = useNavigate()
	const { allBusiness, loaders } = useAppSelector((state) => state.business)
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(getAllBusinessesAction())
	}, [])

	return (
		<Flex direction='column' m='md'>
			<LoadingOverlay visible={loaders.gettingAllBusiness} />
			<Group mb='xs' justify='space-between' align='centre'>
				<Title order={3}>My Business</Title>
				<Button
					onClick={() => navigate('/seller/new-business')}
					leftSection={<IconPlus />}
					color={Colors.primaryColor}
					component='a'
					fw={200}
					radius='xl'
				>
					Add Business
				</Button>
			</Group>
			<SimpleGrid
				cols={{ base: 1, sm: 2, lg: 2 }}
				spacing={{ base: 10, sm: 'xl' }}
				verticalSpacing={{ base: 'md', sm: 'xl' }}
			>
				{allBusiness?.businesses?.map((business, i) => {
					return (
						<Fragment key={i}>
							<BusinessCard business={business} />
						</Fragment>
					)
				})}
			</SimpleGrid>
		</Flex>
	)
}

export default MyBusinesses
