import CenterLayout from "../../components/centerlayout/CenterLayout.tsx";
import {Anchor, Button, Center, Flex, Image, Title} from "@mantine/core";
import checkemail from "../../assets/checkemail.png"
import {useNavigate} from "react-router-dom";
import Colors from "../../constants/colors.ts";

const EmailSent = () => {
    const navigate = useNavigate()
    return (
        <CenterLayout>
            <Center>
                <Flex direction="column" align="center">
                    <Image src={checkemail} w={150} h={150}/>
                    <Title>Check Email Address</Title>
                    <span style={{fontWeight: 400, color: "#A8AEBF"}}>A verification link has been sent to your email address</span>
                </Flex>
            </Center>
            <Button radius="xl" my="lg" size="lg" color={Colors.primaryColor} onClick={() => {
                navigate("/dashboard")
            }} w="100%" mt="lg">Open email</Button>
            <Flex w="100%" justify="center">
                <Anchor style={{color: Colors.primaryColor}}>Resend code</Anchor>
            </Flex>
        </CenterLayout>
    );
};

export default EmailSent;