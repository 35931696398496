import { Button, Flex, Group, Image, rem, Text } from '@mantine/core'
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { IconUpload, IconX } from '@tabler/icons-react'
import Colors from '../constants/colors'

type IDropZoneProps = {
	onDrop: (file: FileWithPath[]) => void
	label: string
	url: File | null | FileWithPath | string
	loading?: boolean
}

const CustomDropzone = ({ onDrop, label, url, loading }: IDropZoneProps) => {
	return (
		<Dropzone
			loading={loading}
			onDrop={onDrop}
			onReject={(files) => console.log('rejected files', files)}
			maxSize={5 * 1024 ** 2}
			accept={IMAGE_MIME_TYPE}
		>
			<Group
				justify='center'
				gap='xl'
				mih={220}
				style={{ pointerEvents: 'none' }}
			>
				<Dropzone.Accept>
					<IconUpload
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-blue-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-red-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Reject>
				{url && (
					<Dropzone.Idle>
						<Image
							h={200}
							w={300}
							src={typeof url === 'string' ? url : URL.createObjectURL(url)}
						/>
					</Dropzone.Idle>
				)}
				{!url && (
					<Flex direction='column' align='center' gap='sm'>
						<Text size='xl' inline>
							{label}
						</Text>
						<Button
							component='a'
							fw={200}
							color={Colors.primaryColor}
							variant='outline'
							radius='xl'
						>
							Choose File
						</Button>
					</Flex>
				)}
			</Group>
		</Dropzone>
	)
}

export default CustomDropzone
