import { Button, Flex, LoadingOverlay, Tabs, Text } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllProductsByBusinessIdAction } from '../../../store/business/businessesActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import ActiveProducts from './ActiveProducts.tsx'
import Colors from '../../../constants/colors.ts'

const Products = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { allBusinessProducts, loaders } = useAppSelector(
		(state) => state.business
	)
	const dispatch = useAppDispatch()
	const [type, setType] = useState<string>('Active')

	useEffect(() => {
		if (!id) return
		dispatch(
			getAllProductsByBusinessIdAction({ id, status: type.toLowerCase() })
		)
	}, [type])

	return (
		<Flex direction='column' p='md'>
			<LoadingOverlay visible={loaders.gettingAllBusinessProducts} />
			<Flex w='100%' justify='space-between' align='center'>
				<Text fw={700} size='24px' my='lg'>
					My Products
				</Text>
				<Button
					fw={200}
					color={Colors.primaryColor}
					size='md'
					radius='xl'
					leftSection={<IconPlus />}
					onClick={() => {
						navigate(`/seller/add-product/${id}`)
					}}
				>
					Add Product
				</Button>
			</Flex>
			<Tabs
				color='lime'
				defaultValue='active'
				onChange={(value) => setType(value ? value : '')}
			>
				<Tabs.List>
					<Tabs.Tab value='active'>Active</Tabs.Tab>
					<Tabs.Tab value='inactive'>Inactive</Tabs.Tab>
					<Tabs.Tab value='rejected'>Rejected</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value='active'>
					<ActiveProducts products={allBusinessProducts?.products} />
				</Tabs.Panel>
				<Tabs.Panel value='inactive'>
					<ActiveProducts products={allBusinessProducts?.products} />
				</Tabs.Panel>
				<Tabs.Panel value='rejected'>
					<ActiveProducts products={allBusinessProducts?.products} />
				</Tabs.Panel>
			</Tabs>
		</Flex>
	)
}

export default Products
