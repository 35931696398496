import { Button, Modal, TextInput } from '@mantine/core'
import {
	GoogleMap,
	Libraries,
	MarkerF,
	useLoadScript,
} from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import pin from '../../assets/placeholder.png'
import axiosInstance from '../../config/axios.config.ts'
import { setNavbarAddress } from '../../store/account/accountSlice.ts'
import { useAppDispatch, useAppSelector } from '../../store/store.ts'
import { setAddress } from '../../utils/storage.ts'
import Colors from '../../constants/colors.ts'

interface GoogleMapModalProps {
	opened: boolean
	onClose: () => void
	coordinates?: MapProps
}

interface MapProps {
	lat?: number
	lng?: number
}

// const initializeMap: MapProps = {
//     lat: 27.994402,
//     lng: -81.760254
// }

const libraries: Libraries = ['places']

const GoogleMapModal = ({ opened, onClose }: GoogleMapModalProps) => {
	const { address, centre } = useAppSelector((state) => state.account)
	const [mark, setMark] = useState<MapProps>(centre)
	const [loading, setLoading] = useState(false)
	const dispatch = useAppDispatch()
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: 'AIzaSyCzH-FCXhV5-SlCbuaiUnKIOW8mP3vYkkA',
		libraries,
	})

	const onClickHandler = (event: google.maps.MapMouseEvent) => {
		if (!event.latLng) return

		const geocoder = new google.maps.Geocoder()

		geocoder.geocode({ location: event.latLng }, async (results) => {
			if (!results || !results.length) return

			const result = results[0]
			const { geometry, formatted_address } = result

			if (!geometry || !geometry.location) return

			const lat = geometry.location.lat()
			const lng = geometry.location.lng()

			setMark({ lat, lng })
			setLoading(true)

			try {
				const { data } = await axiosInstance.get(
					`geocode/address-from-coords?latitude=${lat}&longitude=${lng}`
				)

				setAddress(data.response)

				dispatch(setNavbarAddress(formatted_address))

				localStorage.setItem('from', JSON.stringify({ lat, lng }))
			} catch (error) {
				console.error('Error fetching address details:', error)
			} finally {
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		console.log('center', mark)
	}, [])

	if (loadError) return <div>Error loading maps</div>
	if (!isLoaded) return <div>Loading Maps</div>

	return (
		<Modal
			radius='lg'
			centered
			size='xl'
			title='Add new address'
			opened={opened}
			onClose={onClose}
		>
			<GoogleMap
				zoom={10}
				center={{ lat: mark.lat || 0, lng: mark.lng || 0 }}
				mapContainerStyle={{ height: '350px', width: '100%' }}
				onClick={onClickHandler}
				options={{ isFractionalZoomEnabled: true }}
			>
				{mark && (
					<MarkerF
						position={{ lat: mark.lat || 0, lng: mark.lng || 0 }}
						icon={{ url: pin, scaledSize: new window.google.maps.Size(30, 30) }}
						// draggable={true}
						// onDragEnd={onClickHandler}
					/>
				)}
			</GoogleMap>
			<TextInput value={address} my='sm' size='lg' radius='lg' />
			<Button
				loading={loading}
				onClick={onClose}
				component='a'
				fw={200}
				size='lg'
				radius='xl'
				w='100%'
				color={Colors.primaryColor}
			>
				Save Address
			</Button>
		</Modal>
	)
}

export default GoogleMapModal
