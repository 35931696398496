import {Anchor, Button, Center, em, Flex, PinInput, Text, Title} from "@mantine/core";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {signupAction, verifyOtpAction} from "../../store/account/accountActions.ts";
import {IVerifyOtp} from "../../interfaces/account/IAccount.ts";
import CenterLayout from "./CenterLayout.tsx";
import {useMediaQuery} from "@mantine/hooks";
import Colors from "../../constants/colors.ts";

const Otp = () => {
    const navigate = useNavigate()
    const {loaders} = useAppSelector(state => state.account)
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const location = useLocation()
    const dispatch = useAppDispatch()


    const resendOtp = () => {
        if (location?.state?.email) {
            dispatch(signupAction({email: location?.state?.email}))
        }
    }
    return (
        <CenterLayout>
            <Flex direction='column' justify='center' align='center'>
                <Title fw={500} order={isMobile ? 3 : 1}>
                    OTP Verification
                </Title>
                <Text ta='center'>
                    Enter the Code that we have sent to {location?.state?.email}
                </Text>
            </Flex>
            <Center my="lg">
                <PinInput mask type="number" onComplete={(v) => {
                    const form: IVerifyOtp = {
                        email: location?.state?.email,
                        OTP: Number(v)
                    }
                    dispatch(verifyOtpAction(form)).then((data) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        if (data.payload.success) {
                            if (location?.state?.forgotPassword) {
                                navigate("/reset-password", {state: {otp: form.OTP, email: form.email}})
                            } else {
                                navigate("/name-info")
                            }

                        }
                    })
                }} length={6}/>
            </Center>
            <Button fw={200} radius="xl" loading={loaders.verifyingOtp} onClick={() => {

            }} w="100%" size="lg" color={Colors.primaryColor} my="lg">Verify code</Button>
            <Flex w="100%" justify="center">
                <Anchor style={{color: Colors.primaryColor}} onClick={resendOtp}>Resend code</Anchor>
            </Flex>
        </CenterLayout>
    );
};

export default Otp;