import {
	Button,
	Card,
	em,
	Flex,
	LoadingOverlay,
	NumberInput,
	Select,
	Textarea,
	TextInput,
	Title,
} from '@mantine/core'
import { FileWithPath } from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconMapPin } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomDropzone from '../../../components/CustomDropzone.tsx'
import GoogleMapDrawer from '../../../components/drawers/GoogleMapDrawer.tsx'
import GoogleMapModal from '../../../components/modals/GoogleMapModal.tsx'
import {
	getSingleBusinessByIdAction,
	updateSingleBusinessByIdAction,
} from '../../../store/business/businessesActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { getAddress } from '../../../utils/storage.ts'
import { ICreateBusinessForm } from './AddNewBusiness.tsx'
import Colors from '../../../constants/colors.ts'

const UpdateBusiness = () => {
	const { id } = useParams()
	const [image, setImage] = useState<File | null | FileWithPath>(null)
	const [loading, setLoading] = useState(false)
	const [imageUploading, setImageUploading] = useState(false)
	const { address } = useAppSelector((state) => state.account)
	const { singleBusinessById, loaders } = useAppSelector(
		(state) => state.business
	)
	const [mapOpened, { open, close }] = useDisclosure(false)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const form = useForm<ICreateBusinessForm>({
		initialValues: {
			title: '',
			category: '',
			type: '',
			phoneNumber: '',
			email: '',
			description: '',
			registration: '',
		},

		validate: {
			title: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
			type: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
			phoneNumber: (value: string) =>
				value.length === 0 ? 'Cannot be empty' : null,
			email: (value: string) =>
				/^\S+@\S+$/.test(value) ? null : 'Invalid email',
			description: (value: string) =>
				value.length === 0 ? 'Cannot be empty' : null,
			category: (value) => (value.length === 0 ? 'Cannot be empty' : null),
			registration: (value) => (value.length === 0 ? 'Cannot be empty' : null),
		},
	})
	useEffect(() => {
		if (!id) return
		dispatch(getSingleBusinessByIdAction(id))
	}, [])

	useEffect(() => {
		form.setValues((prev) => ({
			...prev,
			title: singleBusinessById?.business?.title,
			category: singleBusinessById?.business?.category,
			type: singleBusinessById?.business?.type,
			phoneNumber: singleBusinessById?.business?.phoneNumber,
			email: singleBusinessById?.business?.email,
			description: singleBusinessById?.business?.description,
			registration: singleBusinessById?.business?.registrationNumber,
		}))
	}, [singleBusinessById])

	return (
		<Flex direction='column' p='md'>
			<LoadingOverlay visible={loaders.gettingSingleBusinessById} />
			<Title order={3} mb='sm'>
				Edit Business
			</Title>
			<form
				onSubmit={form.onSubmit(async (values) => {
					setLoading(true)
					try {
						const address = getAddress()
						const formData = new FormData()

						formData.append('formattedAddress', address.formattedAddress)
						formData.append('latitude', address.latitude.toString())
						formData.append('longitude', address.longitude.toString())
						formData.append('country', address.country)
						formData.append('isoCode', address.isoCode)
						formData.append('state', address.state)
						formData.append('city', address.city)
						formData.append('coordinates[0]', address.coordinates[0].toString())
						formData.append('coordinates[1]', address.coordinates[1].toString())
						formData.append('name', values.title)
						formData.append('businessPhoneNumber', values.phoneNumber)
						formData.append('category', values.category.toLowerCase())
						formData.append('businessEmail', values.email)
						formData.append('businessDescription', values.description)
						formData.append('registrationNumber', values.registration)
						formData.append(
							'image',
							image ? image : singleBusinessById?.business?.image
						)
						formData.append('businessType', values.type.toLowerCase())
						formData.append('businessId', id ? id : '')

						await dispatch(updateSingleBusinessByIdAction({ data: formData }))

						navigate(-1)
					} catch (error) {
						console.error('Error creating business:', error)
					} finally {
						setLoading(false)
					}
				})}
			>
				<Card>
					<CustomDropzone
						label='Drag & Drop files here to upload'
						onDrop={(file) => {
							setImageUploading(true)
							setImage(file[0])
							setImageUploading(false)
						}}
						loading={imageUploading}
						url={image ? image : singleBusinessById?.business?.image}
					/>

					<TextInput
						defaultValue={singleBusinessById?.business?.title}
						{...form.getInputProps('title')}
						radius='md'
						my='sm'
						size='md'
						label='Store Name'
						placeholder='enter store name'
					/>
					<Select
						{...form.getInputProps('type')}
						radius='md'
						data={['Food', 'Business']}
						size='md'
						label='What type of products you wanna sell?'
						placeholder={singleBusinessById?.business?.type}
					/>
					<Select
						{...form.getInputProps('category')}
						radius='md'
						data={['Restaurants', 'Grocery', 'Pharmacy', 'Other']}
						size='md'
						label='Store Category'
						placeholder={singleBusinessById?.business?.category}
					/>
					<TextInput
						defaultValue={address}
						onClick={open}
						radius='md'
						my='sm'
						size='md'
						label='Address'
						rightSection={<IconMapPin />}
						placeholder='Select Address'
					/>
					<NumberInput
						radius='md'
						size='md'
						prefix='+'
						label='Phone Number'
						placeholder='Enter Phone Number'
						hideControls
						{...form.getInputProps('phoneNumber')}
					/>
					<TextInput
						radius='md'
						size='md'
						my='sm'
						label='Email'
						{...form.getInputProps('email')}
						placeholder='Enter Email'
					/>
					<TextInput
						radius='md'
						size='md'
						my='sm'
						label='Registration'
						{...form.getInputProps('registration')}
						placeholder='Enter Registration Number'
					/>
					<Textarea
						radius='md'
						rows={4}
						my='sm'
						label='Description'
						{...form.getInputProps('description')}
						placeholder='Enter Description'
					/>
					<Flex justify='end' gap='sm' my='sm' w='100%'>
						<Button
							radius='xl'
							color={Colors.primaryColor}
							variant='outline'
							onClick={() => navigate(-1)}
						>
							Cancel
						</Button>
						<Button
							loading={loading}
							radius='xl'
							color={Colors.primaryColor}
							type='submit'
						>
							Update
						</Button>
					</Flex>
				</Card>
			</form>
			{isMobile ? (
				<GoogleMapDrawer opened={mapOpened} onClose={close} />
			) : (
				<GoogleMapModal opened={mapOpened} onClose={close} />
			)}
		</Flex>
	)
}

export default UpdateBusiness
