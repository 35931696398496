import {
	Avatar,
	Card,
	em,
	Flex,
	Indicator,
	LoadingOverlay,
	Text,
	Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconMessage } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	getChatInboxMessagesAction,
	updateChatSeenByAction,
} from '../../../store/chat/chatActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { dateConverter } from '../../../utils/dateConverter.ts'
import { getUserId } from '../../../utils/storage.ts'
import Colors from '../../../constants/colors.ts'

const SellerInbox = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const { inbox, loaders } = useAppSelector((state) => state.chat)
	const dispatch = useAppDispatch()
	const userId = getUserId()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getChatInboxMessagesAction())
	}, [])

	return (
		<Flex w='100%' direction='column' p='md'>
			<LoadingOverlay visible={loaders.gettingInbox} />
			<Title order={3} mb='sm'>
				Messages
			</Title>
			<Flex justify='center'>
				<Flex direction='column' w='100%'>
					{inbox?.chats?.length <= 0 ? (
						<Flex
							w='100%'
							h='70vh'
							align='center'
							justify='center'
							direction='column'
						>
							<IconMessage
								color={Colors.primaryColor}
								stroke={0.7}
								size={isMobile ? 150 : 250}
							/>
							<Text>
								No{' '}
								<span style={{ color: Colors.primaryColor }}>Messages</span>{' '}
								found
							</Text>
						</Flex>
					) : (
						<Card radius='md'>
							{inbox?.chats?.map((chat, index) => {
								return (
									<Flex
										onClick={() => {
											dispatch(
												updateChatSeenByAction({ chatRoomId: chat?.chatRoomId })
											)
											navigate(
												`/seller/chat/${chat?.chatPartnerInfo?._id}?name=${chat?.chatPartnerInfo?.personalInformation?.firstName}&avatar=${chat?.chatPartnerInfo?.personalInformation?.profilePic}`
											)
										}}
										style={{ cursor: 'pointer' }}
										key={index}
										my='md'
										align='center'
										gap='sm'
										justify='space-between'
									>
										<Flex gap='sm'>
											<Indicator
												color='red'
												disabled={chat?.seenBy === userId}
												style={{ zIndex: 1 }}
											>
												<Avatar
													src={
														chat?.chatPartnerInfo?.personalInformation
															?.profilePic
													}
												/>
											</Indicator>
											<Flex direction='column'>
												<Text lineClamp={1} size={isMobile ? 'sm' : ''}>
													{
														chat?.chatPartnerInfo?.personalInformation
															?.firstName
													}
												</Text>
												<Text
													opacity={0.5}
													size={isMobile ? 'sm' : ''}
													lineClamp={1}
												>
													<div
														dangerouslySetInnerHTML={{
															__html: chat?.mostRecentMessage?.text,
														}}
													/>
												</Text>
											</Flex>
										</Flex>
										<Text opacity={0.5} size={isMobile ? 'xs' : ''}>
											{dateConverter(chat?.mostRecentMessage?.createdAt)}
										</Text>
									</Flex>
								)
							})}
						</Card>
					)}
				</Flex>
			</Flex>
		</Flex>
	)
}

export default SellerInbox
