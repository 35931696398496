import { Button, Drawer, TextInput } from '@mantine/core'
import {
	GoogleMap,
	Libraries,
	MarkerF,
	useLoadScript,
} from '@react-google-maps/api'
import { useState } from 'react'
import pin from '../../assets/placeholder.png'
import axiosInstance from '../../config/axios.config.ts'
import { IAddressResponse } from '../../interfaces/geocods/IAddressResponse.ts'
import { setNavbarAddress } from '../../store/account/accountSlice.ts'
import { useAppDispatch, useAppSelector } from '../../store/store.ts'
import classes from './GoogleMapDrawer.module.css'
import Colors from '../../constants/colors.ts'

interface GoogleMapModalProps {
	opened: boolean
	onClose: () => void
	coordinates?: MapProps
}

interface MapProps {
	lat?: number
	lng?: number
}

const libraries: Libraries = ['places']
// const initializeMap: MapProps = {
//     lat: 27.994402,
//     lng: -81.760254
// }
const GoogleMapDrawer = ({ opened, onClose }: GoogleMapModalProps) => {
	const { address, centre } = useAppSelector((state) => state.account)

	const [mark, setMark] = useState<MapProps>(centre)
	// const [mapCenter, setMapCenter] = useState(centre)
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: 'AIzaSyCzH-FCXhV5-SlCbuaiUnKIOW8mP3vYkkA',
		libraries,
	})

	const [loading, setLoading] = useState(false)

	const dispatch = useAppDispatch()

	const onClickHandler = (event: google.maps.MapMouseEvent) => {
		if (!event.latLng) return

		const geocoder = new google.maps.Geocoder()
		geocoder.geocode({ location: event.latLng }, async (results) => {
			if (!results || !results.length) return

			const result = results[0]
			const { geometry, place_id, formatted_address } = result

			if (!geometry || !geometry.location) return

			const lat = geometry.location.lat()
			const lng = geometry.location.lng()

			setMark({ lat, lng })
			setLoading(true)

			try {
				const { data } = await axiosInstance.get<IAddressResponse>(
					`geocode/autoCompleteAddressDetails?placeId=${place_id}`
				)

				localStorage.setItem('address', JSON.stringify(data.response))

				dispatch(setNavbarAddress(formatted_address))

				localStorage.setItem('from', JSON.stringify({ lat, lng }))
			} catch (error) {
				console.error('Error fetching address details:', error)
			} finally {
				setLoading(false)
			}
		})
	}

	if (loadError) return <div>Error loading maps</div>
	if (!isLoaded) return <div>Loading Maps</div>

	return (
		<Drawer onClose={onClose} opened={opened} position='bottom' size='70%'>
			<GoogleMap
				zoom={8}
				center={centre}
				mapContainerStyle={{ height: '350px', width: '100%' }}
				options={{
					zoomControl: true,
					streetViewControl: true,
					mapTypeControl: true,
					fullscreenControl: false,
				}}
			>
				{mark && (
					<MarkerF
						position={
							mark?.lat && mark?.lng
								? { lat: mark.lat, lng: mark.lng }
								: {
										lat: 0,
										lng: 0,
								  }
						}
						icon={{
							url: pin,
							scaledSize: new window.google.maps.Size(30, 30),
						}}
						draggable={true}
						onDragEnd={onClickHandler}
					/>
				)}
			</GoogleMap>
			<TextInput
				classNames={{ label: classes.label }}
				label='Address'
				value={address}
				my='sm'
				size='lg'
				radius='lg'
			/>
			<Button
				loading={loading}
				onClick={onClose}
				component='a'
				fw={100}
				size='xxl'
				radius='xl'
				w='100%'
				color={Colors.primaryColor}
			>
				Save Address
			</Button>
		</Drawer>
	)
}

export default GoogleMapDrawer
