import {
	ActionIcon,
	Box,
	Card,
	CloseButton,
	em,
	Flex,
	Group,
	Image,
	Skeleton,
	Stack,
	Text,
	Title,
} from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconMinus, IconPlus, IconTicket } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import CouponsModal from '../../../components/modals/CouponsModal.tsx'
import { Item } from '../../../interfaces/buyer/cart/ICartUpdateData.ts'
import {
	Detail,
	Product,
} from '../../../interfaces/buyer/cart/IUpdatetdCartResponse.ts'
import {
	getAllCartItems,
	getAllCoupons,
	updateCartAction,
	updateCartItemAction,
} from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { daysCalculator } from '../../../utils/daysCalculator.ts'
import { round } from '../../../utils/roundOffFunction.ts'
import Colors from '../../../constants/colors.ts'

type ItemProps = {
	items: Detail
	loading?: boolean
	totalAmount: number
}
const MyBagItems = ({ items, loading }: ItemProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const [cartItems, setCartItems] = useState<Product[]>(items?.products)
	const [opened, { open, close }] = useDisclosure(false)
	const { allCoupons, cart, loaders } = useAppSelector((state) => state.product)
	const dispatch = useAppDispatch()
	const discountItem = items?.total?.find((item) => item.title === 'Discount')
	const [couponId, setCouponId] = useState('')

	const updateQuantity = (id: string, newQuantity: number) => {
		const extractProductDetails = () => {
			const productDetails: Item[] = []

			cart.cart.details.forEach((detail: any) => {
				detail.products.forEach((product: any) => {
					const { _id, quantity } = product
					productDetails.push({ product: _id, quantity })
				})
			})

			return productDetails
		}
		const extractedProductDetails = extractProductDetails()
		const index = extractedProductDetails.findIndex(
			(item) => item.product === id
		)

		if (index !== -1) {
			if (newQuantity === 0) {
				// If new quantity is zero, remove the product from the array
				extractedProductDetails.splice(index, 1)
			} else {
				// Update the quantity of the product
				extractedProductDetails[index].quantity = newQuantity
			}

			dispatch(updateCartItemAction({ items: extractedProductDetails })).then(
				(value) => {
					if (value) {
						dispatch(getAllCartItems())
					}
				}
			)
		}
	}

	useEffect(() => {
		setCartItems(items?.products)
	}, [items])

	// useEffect(() => {
	//     const updatedCartItems = cartItems.map(item => ({
	//         product: item._id,
	//         quantity: item.quantity
	//     }));
	//     const data: ICartUpdateData = {items: updatedCartItems, couponInfo: couponUpdateData}
	//     // dispatch(updateCartAction(data))
	// }, [couponUpdateData]);

	return (
		<Card radius='lg' w='100%' shadow='xs' my='sm'>
			<Flex direction='column' w='100%' gap='xl' px={isMobile ? '' : 'sm'}>
				<Title order={3}>{items?.business?.title}</Title>
				<Group>
					<Text opacity={0.5}>Delivery Time:</Text>
					<Text>{daysCalculator(items?.deliveryTime)} days</Text>
				</Group>
				{loading ? (
					<Flex>
						<Skeleton h='60px' w='60px' />
						<Flex mx='lg' direction='column'>
							<Skeleton />
							<Flex gap='md'>
								<Skeleton />
							</Flex>
						</Flex>
					</Flex>
				) : (
					cartItems?.map((value) => {
						return (
							<Flex align='center' key={value._id} justify='space-between'>
								<Flex align='center'>
									<Image h='60px' w='60px' src={value.productImage} />
									<Flex mx='lg' direction='column'>
										<Box w={isMobile ? 160 : 250}>
											<Text size='sm' truncate='end'>
												{value.productName}
											</Text>
										</Box>
										<Flex gap='md'>
											<Text size='sm' fw={10} td={'line-through'} opacity={0.5}>
												{round(value?.priceBeforeDiscount?.amount)}
											</Text>
											<Text
												size='sm'
												style={{
													color: 'black',
													fontWeight: 600,
												}}
											>
												{round(value?.price?.amount)} {value?.price?.currency}
											</Text>
											{/*<Text size="sm"*/}
											{/*      style={{{color: Colors.primaryColor}}}>{value}%*/}
											{/*    off</Text>*/}
										</Flex>
									</Flex>
								</Flex>
								<Card
									radius='xl'
									p='8px'
									bg='#F5F6FA'
									w={isMobile ? '120px' : '150px'}
									h={isMobile ? '35px' : '45px'}
								>
									<Flex h='100%' align='center' justify='space-between'>
										<ActionIcon
											size={isMobile ? 'xs' : 'md'}
											onClick={() => {
												if (value.quantity <= 1) {
													updateQuantity(value._id, 0)
													return
												}
												updateQuantity(value._id, value.quantity - 1)
											}}
											bg='white'
											radius='xl'
											c='black'
											variant='subtle'
										>
											<IconMinus />
										</ActionIcon>
										<Text>{value.quantity}</Text>
										<ActionIcon
											size={isMobile ? 'xs' : 'md'}
											onClick={() =>
												updateQuantity(value._id, value.quantity + 1)
											}
											radius='xl'
											color={Colors.primaryColor}
										>
											<IconPlus />
										</ActionIcon>
									</Flex>
								</Card>
							</Flex>
						)
					})
				)}
				<Group
					style={{ cursor: 'pointer' }}
					onClick={() => {
						dispatch(getAllCoupons())
						open()
					}}
				>
					<IconTicket />
					<Text>{couponId ? couponId : 'Apply a coupon'}</Text>
					{couponId && <Text opacity={0.5}>(Coupon Applied)</Text>}
					{discountItem && (
						<CloseButton
							onClick={(event) => {
								event.stopPropagation()
								const extractProductDetails = () => {
									const productDetails: Item[] = []
									cart.cart.details.forEach((detail: any) => {
										detail.products.forEach((product: any) => {
											const { _id, quantity } = product
											productDetails.push({ product: _id, quantity })
										})
									})
									return productDetails
								}
								const extractedProductDetails = extractProductDetails()
								dispatch(updateCartAction({ items: extractedProductDetails }))
							}}
						/>
					)}
				</Group>
				<Group w='100%' justify='space-between' align='start'>
					<Text>{items?.count} Items</Text>
					<Stack>
						{items?.total?.map((total, index) => {
							return (
								<Group key={index}>
									<Text>{total?.title} :</Text>
									<Text opacity={0.5}>
										{round(total?.price?.amount)} {total?.price?.currency}
									</Text>
								</Group>
							)
						})}
					</Stack>
				</Group>
			</Flex>
			<CouponsModal
				setCouponId={setCouponId}
				id={items?.business?._id}
				coupons={allCoupons.inactiveCoupons}
				opened={opened}
				close={close}
				loading={loaders.gettingAllCoupons}
			/>
		</Card>
	)
}

export default MyBagItems
