import {
	Button,
	Flex,
	Group,
	Modal,
	Rating,
	Text,
	Textarea,
} from '@mantine/core'
import React from 'react'
import { IReviewProductSubmit } from '../../interfaces/buyer/product/IReviewProductSubmit.ts'
import {
	getAllUnreviewedProductsAction,
	submitReviewAction,
} from '../../store/product/productActions.ts'
import { useAppDispatch } from '../../store/store.ts'
import Colors from '../../constants/colors.ts'

type RatingModalProps = {
	opened: boolean
	close: () => void
	setRatingForm: React.Dispatch<React.SetStateAction<IReviewProductSubmit>>
	ratingForm: IReviewProductSubmit
}

const RatingModal = ({
	opened,
	close,
	setRatingForm,
	ratingForm,
}: RatingModalProps) => {
	const dispatch = useAppDispatch()
	return (
		<Modal opened={opened} onClose={close} centered title='Write review'>
			<Flex direction='column' gap='lg'>
				<Group justify='space-between'>
					<Text>Rate Product</Text>
					<Rating
						defaultValue={5}
						onChange={(e) => {
							console.log(e)
							setRatingForm({ ...ratingForm, rating: e })
						}}
						size='lg'
					/>
				</Group>
				<Textarea
					placeholder='Say something about product'
					onChange={(e) => {
						console.log(e.target.value)
						setRatingForm({ ...ratingForm, review: e.target.value })
					}}
					label='Review '
				/>
			</Flex>
			<Flex justify='center' mt='sm'>
				<Button
					fw={200}
					w='30%'
					radius='xl'
					color={Colors.primaryColor}
					onClick={() => {
						dispatch(submitReviewAction(ratingForm))
						dispatch(getAllUnreviewedProductsAction())
						close()
					}}
				>
					Submit
				</Button>
			</Flex>
		</Modal>
	)
}

export default RatingModal
