import {
	Alert,
	Avatar,
	Button,
	em,
	Flex,
	Loader,
	Select,
	Text,
	TextInput,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { Dropzone } from '@mantine/dropzone'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleMapDrawer from '../../../../components/drawers/GoogleMapDrawer.tsx'
import GoogleMapModal from '../../../../components/modals/GoogleMapModal.tsx'
import axiosInstance from '../../../../config/axios.config.ts'
import {
	IAuthUserUpdateResponse,
	PersonalInformation,
} from '../../../../interfaces/account/IAuthUserUpdateResponse.ts'
import {
	getProfileDataAction,
	profileUpdateAction,
} from '../../../../store/account/accountActions.ts'
import { useAppDispatch, useAppSelector } from '../../../../store/store.ts'
import { getUser, setAuthUser } from '../../../../utils/storage.ts'
import Colors from '../../../../constants/colors.ts'

type MyAccountProps = {
	setProfileData: (data: PersonalInformation) => void
	profileData: PersonalInformation
}

const MyAccount = ({ setProfileData, profileData }: MyAccountProps) => {
	const [mapOpened, { open, close }] = useDisclosure(false)
	const authUser = getUser()
	const [profilePic, setProfilePic] = useState(authUser?.profilePic)
	// const [loading, setLoading] = useState(false)
	const [profile, setProfile] = useState(false)
	const [alert, setAlert] = useState(false)
	const [pendingAlert, setPendingAlert] = useState(false)
	const navigate = useNavigate()
	const { address, loaders } = useAppSelector((state) => state.account)
	const dispatch = useAppDispatch()
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const openRef = useRef<() => void>(null)

	useEffect(() => {
		dispatch(getProfileDataAction())
	}, [])

	return (
		<>
			{loaders.gettingUserProfile ? (
				<Flex h='500px' w='100%' justify='center' align='center'>
					<Loader />
				</Flex>
			) : (
				<Flex direction='column' justify='center' align='center'>
					<Flex direction='column' w='100%' p='md'>
						<Text fw={700} size='24px'>
							My Account
						</Text>
						<Flex w='100%' direction='column' p={isMobile ? '' : 'lg'} gap='lg'>
							<Flex
								w='100%'
								direction={isMobile ? 'column' : 'row'}
								align='center'
								justify='space-between'
								gap='lg'
							>
								<Flex
									direction={isMobile ? 'column' : 'row'}
									align='center'
									gap='sm'
								>
									<Dropzone
										unstyled
										onReject={(files) => console.log('rejected files', files)}
										maxSize={5 * 1024 ** 2}
										openRef={openRef}
										onDrop={async (files) => {
											const formData = new FormData()
											formData.append('profilePic', files[0])
											const res =
												await axiosInstance.put<IAuthUserUpdateResponse>(
													'auth/update-profile-pic',
													formData
												)
											setAuthUser({
												firstName:
													res.data.updatedProfilePic.personalInformation
														.firstName,
												lastName:
													res.data.updatedProfilePic.personalInformation
														.lastName,
												profilePic:
													res.data.updatedProfilePic.personalInformation
														.profilePic,
												email:
													res.data.updatedProfilePic.credentialDetails.email,
												role: res.data.updatedProfilePic.role,
												loyaltyPoints: res.data.updatedProfilePic.loyaltyPoints,
												ageVerification:
													res.data.updatedProfilePic.personalInformation.dob,
												phoneNumber:
													res.data.updatedProfilePic.personalInformation
														.phoneNumber,
												dob: res.data.updatedProfilePic.personalInformation.dob,
												gender:
													res.data.updatedProfilePic.personalInformation.gender,
												currency:
													res.data.updatedProfilePic.preferences.currency.name,
											})
											setProfilePic(
												res?.data?.updatedProfilePic?.personalInformation
													?.profilePic
											)
										}}
									>
										<Avatar
											style={{ cursor: 'pointer' }}
											src={profilePic}
											size='xl'
										/>
									</Dropzone>
									<Flex direction='column'>
										<Text ta={isMobile ? 'center' : 'start'} fw={400}>
											{authUser?.firstName ? authUser?.firstName : 'name'}
										</Text>
										<Text fw={400} opacity={0.5}>
											{authUser?.email ? authUser?.email : 'email@mail.com'}
										</Text>
									</Flex>
								</Flex>
								{!profile && (
									<Button
										radius='xl'
										variant='outline'
										fw={50}
										color={Colors.primaryColor}
										size='sm'
										onClick={() => {
											setProfile(!profile)
										}}
									>
										Edit profile
									</Button>
								)}
							</Flex>
							<Flex
								w='100%'
								justify='space-between'
								direction={isMobile ? 'column' : 'row'}
								gap={isMobile ? 'lg' : ''}
							>
								<TextInput
									defaultValue={authUser?.firstName}
									disabled={!profile}
									onChange={(v) => {
										setProfileData({
											...profileData,
											firstName: v.target.value,
										})
									}}
									w={isMobile ? '100%' : '40%'}
									label='First Name'
									placeholder={authUser?.firstName}
									size={isMobile ? 'sm' : 'md'}
									radius='md'
								/>
								<TextInput
									defaultValue={authUser?.lastName}
									disabled={!profile}
									onChange={(v) => {
										setProfileData({ ...profileData, lastName: v.target.value })
									}}
									w={isMobile ? '100%' : '40%'}
									label='Last name'
									placeholder={authUser?.lastName}
									size={isMobile ? 'sm' : 'md'}
									radius='md'
								/>
							</Flex>
							<Flex
								w='100%'
								justify='space-between'
								direction={isMobile ? 'column' : 'row'}
								gap={isMobile ? 'lg' : ''}
							>
								<TextInput
									defaultValue={authUser?.phoneNumber}
									disabled={!profile}
									onChange={(v) => {
										setProfileData({
											...profileData,
											phoneNumber: v.target.value,
										})
									}}
									w={isMobile ? '100%' : '40%'}
									label='Phone'
									size={isMobile ? 'sm' : 'md'}
									radius='md'
								/>
								<TextInput
									disabled={!profile}
									onClick={open}
									defaultValue={address}
									w={isMobile ? '100%' : '40%'}
									label='Address'
									size={isMobile ? 'sm' : 'md'}
									radius='md'
								/>
							</Flex>

							<Flex
								w='100%'
								justify='space-between'
								direction={isMobile ? 'column' : 'row'}
								gap={isMobile ? 'lg' : ''}
							>
								<Select
									disabled={!profile}
									w={isMobile ? '100%' : '40%'}
									size={isMobile ? 'sm' : 'md'}
									radius='md'
									withCheckIcon={false}
									rightSection={''}
									label='Gender'
									defaultValue={authUser.gender}
									placeholder={authUser.gender}
									data={['Male', 'Female', 'Not Specified']}
									onChange={(e) =>
										setProfileData({ ...profileData, gender: e ? e : '' })
									}
								/>
								<Flex
									w={isMobile ? '100%' : '40%'}
									align='center'
									gap='sm'
									justify='space-between'
								>
									<DatePickerInput
										disabled={!profile}
										clearable
										w={isMobile ? '100%' : '100%'}
										defaultValue={new Date(authUser.dob)}
										label='Date of birth'
										placeholder='Pick date'
										size={isMobile ? 'sm' : 'md'}
										radius='md'
										onChange={(e) => {
											// let options = {year: 'numeric', month: '2-digit', day: '2-digit'}
											if (e) {
												setProfileData({
													...profileData,
													dob: e.toLocaleDateString(),
												})
											}
										}}
									/>
								</Flex>
							</Flex>
							{profile && (
								<Flex gap='sm' align='center' justify='end' my='sm'>
									<Button
										radius='xl'
										fw={50}
										color={Colors.primaryColor}
										size='sm'
										onClick={() => {
											dispatch(profileUpdateAction(profileData)).then(
												(value) => {
													if (value.payload) {
														navigate('/buyer')
													}
												}
											)
										}}
									>
										Save
									</Button>
									<Button
										radius='xl'
										variant='outline'
										fw={50}
										color={Colors.primaryColor}
										size='sm'
										onClick={() => setProfile(!profile)}
									>
										Cancel
									</Button>
								</Flex>
							)}
						</Flex>
						{isMobile ? (
							<GoogleMapDrawer opened={mapOpened} onClose={close} />
						) : (
							<GoogleMapModal opened={mapOpened} onClose={close} />
						)}
						{alert && (
							<Alert
								onClose={() => setAlert(false)}
								withCloseButton
								my='sm'
								variant='light'
								color='red'
								title='Age Verified'
							>
								Your age has already been verified
							</Alert>
						)}
						{pendingAlert && (
							<Alert
								onClose={() => setPendingAlert(false)}
								withCloseButton
								my='sm'
								variant='light'
								color='red'
								title='Age Verification Pending'
							>
								Your age verification is pending
							</Alert>
						)}
					</Flex>
				</Flex>
			)}
		</>
	)
}

export default MyAccount
