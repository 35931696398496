import {
	Badge,
	Card,
	em,
	Flex,
	LoadingOverlay,
	TextInput,
	Title,
} from '@mantine/core'
import type * as Square from '@square/web-sdk'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { useMediaQuery } from '@mantine/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//@ts-expect-error
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import environments from '../../../../config/environments.ts'
import { IWalletTopUp } from '../../../../interfaces/wallet/IWalletTopUp.ts'
import { useAppDispatch, useAppSelector } from '../../../../store/store.ts'
import { topUpAction } from '../../../../store/wallet/walletActions.ts'
import Colors from '../../../../constants/colors.ts'

const DepositMoney = () => {
	const [selected, setSelected] = useState('100')
	// const [opened, {open, close}] = useDisclosure(false);
	const { loaders } = useAppSelector((state) => state.wallet)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const handleDeposit = (token: string) => {
		const depositData: IWalletTopUp = {
			cnon: token,
			amount: parseInt(selected),
			currency: 'USD',
		}
		dispatch(topUpAction(depositData)).then(() => {
			navigate(-1)
		})
	}

	return (
		<Flex w='100%' direction='column'>
			<LoadingOverlay visible={loaders.makingTopUp} />
			<Card w='100%' pos='fixed' style={{ zIndex: '100' }}>
				<Flex w='100%' justify='center'>
					<Flex
						justify='space-between'
						align='center'
						w={isMobile ? '100%' : '70%'}
					>
						<Title order={isMobile ? 4 : 3}>Deposit Money</Title>
						{/*<Button radius="xl" color={Colors.primaryColor}>Create Coupon</Button>*/}
					</Flex>
				</Flex>
			</Card>
			<Flex justify='center' mt={60}>
				<Flex direction='column' w={isMobile ? '100%' : 500} p='md'>
					<Card shadow='sm'>
						<Flex gap='sm' direction='column'>
							<TextInput
								value={selected ? selected : ''}
								onChange={(e) => {
									setSelected(e.target.value)
								}}
								label='Amount'
							/>
							<Flex gap='sm'>
								<Badge
									style={{ cursor: 'pointer' }}
									size='xl'
									variant={selected === '100' ? 'outline' : ''}
									color={selected === '100' ? Colors.primaryColor : '#F5F6FA'}
									c={selected === '100' ? Colors.primaryColor : 'black'}
									radius='xl'
									onClick={() => setSelected('100')}
								>
									$ 100
								</Badge>
								<Badge
									radius='xl'
									style={{ cursor: 'pointer' }}
									size='xl'
									variant={selected === '200' ? 'outline' : ''}
									color={selected === '200' ? Colors.primaryColor : '#F5F6FA'}
									c={selected === '200' ? Colors.primaryColor : 'black'}
									onClick={() => setSelected('200')}
								>
									$ 200
								</Badge>
								<Badge
									radius='xl'
									style={{ cursor: 'pointer' }}
									size='xl'
									variant={selected === '500' ? 'outline' : ''}
									color={selected === '500' ? Colors.primaryColor : '#F5F6FA'}
									c={selected === '500' ? Colors.primaryColor : 'black'}
									onClick={() => setSelected('500')}
								>
									$ 500
								</Badge>
							</Flex>
							<PaymentForm
								// applicationId="sandbox-sq0idb-QdAGdL8V12JjVBDZTyQVgg"
								applicationId={environments.squareAppID}
								cardTokenizeResponseReceived={(token: Square.TokenResult) => {
									if (!token.token) return
									console.log('token', token.token)
									handleDeposit(token?.token)
								}}
								locationId='L4M3BD0TX1M8X'
							>
								<CreditCard
									buttonProps={{
										css: {
											backgroundColor: Colors.primaryColor,
											fontSize: '14px',
											color: '#fff',
											'&:hover': {
												backgroundColor: Colors.primaryColor,
											},
										},
									}}
								/>
							</PaymentForm>
						</Flex>
					</Card>
				</Flex>
			</Flex>
			{/*<Modal opened={opened} onClose={close} centered>*/}
			{/*    {loaders.makingTopUp ?*/}
			{/*        <Flex h="100%" w="100%" justify="center" align="center">*/}
			{/*            <Loader/>*/}
			{/*        </Flex> :*/}
			{/*        <Flex direction="column" align="center" gap="sm">*/}
			{/*            <Box h={100} w={100}>*/}
			{/*                <Image src={success}/>*/}
			{/*            </Box>*/}
			{/*            <Text fw={600}>Money Deposit</Text>*/}
			{/*            <Text opacity={0.5}>Money has been Deposit Successfully!</Text>*/}
			{/*            <Button component="a" fw={200} color={Colors.primaryColor} radius="xl" w="100%"*/}
			{/*                    onClick={() => close()}>Great</Button>*/}
			{/*        </Flex>*/}
			{/*    }*/}
			{/*</Modal>*/}
		</Flex>
	)
}

export default DepositMoney
