import { Carousel } from '@mantine/carousel'
import {
	Anchor,
	Box,
	Card,
	em,
	Flex,
	Image,
	Progress,
	rem,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
	IconArrowLeft,
	IconArrowRight,
	IconChevronRight,
} from '@tabler/icons-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import left from '../../assets/left.svg'
import right from '../../assets/right.svg'
import crazyStar from '../../assets/star.png'
import storyPoint from '../../assets/story-point.png'
import RestaurantsCardsNew from '../../components/RestaurantsCardsNew.tsx'
import CategorySkeleton from '../../components/skeletons/CategorySkeleton.tsx'
import ProductInfoSkeleton from '../../components/skeletons/ProductInfoSkeleton.tsx'
import RestaurantsSkeleton from '../../components/skeletons/RestaurantsSkeleton.tsx'
import { getAllCategoriesAction } from '../../store/category/categoriesActions.ts'
import { getAllProductsAction } from '../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../store/store.ts'
import { notificationSystem } from '../../utils/notificationSystem.ts'
import classes from '../buyer/home/carousel.module.css'
import ProductCardNew from '../buyer/product/ProductCardNew.tsx'
import GuestHome from './GuestHome.tsx'
import Colors from '../../constants/colors.ts'

const GuestHomePage = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { products, loaders } = useAppSelector((state) => state.product)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)

	useEffect(() => {
		dispatch(getAllProductsAction({ lat: 30.2302168, lng: 71.4892897 }))
		dispatch(getAllCategoriesAction())
	}, [])
	return (
		<GuestHome
			slider={products?.data?.homeSlides}
			topCagtories={products?.data?.topCagtories}
			loading={loaders.gettingAllProducts}
		>
			<Flex direction='column' maw={isMobile ? '100%' : '75%'}>
				<Flex direction='column'>
					<Flex
						hiddenFrom='lg'
						justify='space-evenly'
						ml={isMobile ? 'lg' : ''}
						align='center'
						wrap={isMobile ? 'wrap' : 'nowrap'}
						mb='xl'
						gap='sm'
						pb='lg'
						w={isMobile ? '90%' : '100%'}
						h={isMobile ? 150 : 150}
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
									<CategorySkeleton key={index} />
							  ))
							: products?.data?.topCagtories?.map((value, index) => {
									return (
										<Flex
											h={isMobile ? '40%' : '50%'}
											w={isMobile ? 70 : 70}
											my='lg'
											align='center'
											justify='space-between'
											pos='relative'
											key={index}
											direction='column'
											gap={isMobile ? ' ' : 'sm'}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												const params = encodeURIComponent(value.name)
												navigate(`guest-category?category=${params}`)
											}}
										>
											<Box style={{ zIndex: 2 }} w={isMobile ? 50 : 70}>
												<Image
													right={15}
													h={isMobile ? '100%' : '100%'}
													src={value.image}
												/>
											</Box>
											<Card
												radius='md'
												component='a'
												style={{ zIndex: 1 }}
												opacity={0.3}
												top={isMobile ? -10 : -15}
												bg={Colors.primaryColor}
												pos='absolute'
												h={isMobile ? 50 : 70}
												w={isMobile ? 50 : 70}
											></Card>

											<Text
												ta='center'
												size={isMobile ? 'sm' : ''}
												fw={isMobile ? 500 : 700}
											>
												{value.name}
											</Text>
										</Flex>
									)
							  })}
					</Flex>
					<Card
						withBorder
						mt={isMobile ? 'xl' : ''}
						radius='xl'
						hiddenFrom='sm'
						mx='sm'
						p={0}
						h='100px'
						style={{ background: Colors.primaryColor }}
					>
						<Box h={70} w={70}>
							<Image w={70} h={65} src={left} pos='absolute' />
						</Box>
						<Flex align='center' h='100%' justify='space-between' m='xs'>
							<Box>
								<Image h={60} w={60} src={storyPoint} pos='absolute' top={20} />
							</Box>
							<Flex direction='column' w='65%' ml='xl'>
								<Flex gap='xs' align='center' mb={2}>
									<Text c='white' ta='center' size='14px' fw={200}>
										0 CRAZY Stars{' '}
									</Text>
									<Image h={20} w={20} src={crazyStar} />
								</Flex>
								<Progress color='#274C07' bg='#45850D' value={0} />
								<Text my={2} c='white' size='xs'>
									Join{' '}
									<Text span fw={700}>
										CRAZY CUB
									</Text>
									, an exclusive loyalty and rewards program!
								</Text>
							</Flex>
							<IconChevronRight color='white' />
						</Flex>
						<Image
							h='100%'
							w={71}
							pos='absolute'
							right={-10}
							top={16}
							src={right}
						/>
					</Card>
					<Flex
						my={isMobile ? 'lg' : ''}
						justify='space-between'
						m={isMobile ? 'xs' : ''}
					>
						<Text fw={700}>
							{products?.data?.categoryProducts[0]?.category}
						</Text>
						<Anchor
							style={{ color: Colors.primaryColor }}
							underline='never'
							onClick={() => {
								const param = encodeURIComponent(
									products?.data?.categoryProducts[0]?.category
								)
								navigate(`view-all?category=${param}`)
							}}
						>
							View all
						</Anchor>
					</Flex>
					<Carousel
						nextControlIcon={
							<IconArrowRight
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						previousControlIcon={
							<IconArrowLeft
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						slideSize={isMobile ? '160px' : '160px'}
						slideGap={0}
						align='start'
						dragFree
						classNames={classes}
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
									<Carousel.Slide key={index}>
										<ProductInfoSkeleton />
									</Carousel.Slide>
							  ))
							: products?.data?.categoryProducts[0]?.products?.map(
									(product, index) => {
										return (
											<Carousel.Slide key={index}>
												<ProductCardNew product={product} />
											</Carousel.Slide>
										)
									}
							  )}
					</Carousel>
					<Flex my='lg' justify='space-between' m='xs'>
						<Text fw={700}>Restaurants near you</Text>
						<Anchor
							style={{ color: Colors.primaryColor }}
							underline='never'
							onClick={() => {
								navigate('/sign-in')
								notificationSystem(
									'You need to login to view all restaurants',
									'',
									true
								)
							}}
						>
							View all
						</Anchor>
					</Flex>
					<Carousel
						nextControlIcon={
							<IconArrowRight
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						previousControlIcon={
							<IconArrowLeft
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						slideSize='180px'
						// slideSize={350}
						slideGap={0}
						align='start'
						dragFree
						classNames={classes}
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
									<Carousel.Slide key={index}>
										<RestaurantsSkeleton />
									</Carousel.Slide>
							  ))
							: products?.data?.nearbyRestaurants?.map((c, index) => {
									return (
										<div key={index}>
											<RestaurantsCardsNew user={'guest'} restaurant={c} />
										</div>
									)
							  })}
					</Carousel>
					{/*</ScrollArea>*/}
					{products?.data?.categoryProducts?.length > 0 &&
						products.data.categoryProducts.map((c, index) => {
							if (c.category.includes('Grocery')) return
							return (
								<div key={index}>
									<Flex my='lg' justify='space-between' m='xs'>
										<Text fw={700}>{c.category}</Text>
										<Anchor
											style={{ color: Colors.primaryColor }}
											underline='never'
											onClick={() => {
												const param = encodeURIComponent(c.category)
												navigate(`view-all?category=${param}`)
											}}
										>
											View all
										</Anchor>
									</Flex>
									<Carousel
										my='lg'
										nextControlIcon={
											<IconArrowRight
												style={{
													width: rem(30),
													height: rem(30),
													boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
													borderRadius: '100px',
												}}
											/>
										}
										previousControlIcon={
											<IconArrowLeft
												style={{
													width: rem(30),
													height: rem(30),
													boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
													borderRadius: '100px',
												}}
											/>
										}
										slideSize={isMobile ? '160px' : '160px'}
										slideGap={0}
										align='start'
										dragFree
										classNames={classes}
									>
										{loaders.gettingAllProducts
											? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
													<Carousel.Slide key={index}>
														<ProductInfoSkeleton />
													</Carousel.Slide>
											  ))
											: c.products?.map((product, index) => {
													return (
														<Carousel.Slide key={index}>
															<ProductCardNew product={product} />
														</Carousel.Slide>
													)
		 })}
									</Carousel>
								</div>
							)
						})}
				</Flex>
			</Flex>
		</GuestHome>
	)
}

export default GuestHomePage
