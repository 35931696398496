import {
	IconCookie,
	IconGauge,
	IconLock,
	IconMessage2,
	IconUser,
} from '@tabler/icons-react'

export const featuresData = [
	{
		icon: IconGauge,
		title: 'Exceptional Performance',
		description:
			'Enjoy a seamless shopping experience with our fast and reliable platform, designed to handle all your needs effortlessly.',
	},
	{
		icon: IconUser,
		title: 'Customer-Centric Approach',
		description:
			'We prioritize your satisfaction by offering personalized recommendations and a hassle-free shopping journey.',
	},
	{
		icon: IconCookie,
		title: 'No Middlemen',
		description:
			'All products are sourced directly from trusted suppliers, ensuring quality and authenticity at the best prices.',
	},
	{
		icon: IconLock,
		title: 'Top-Notch Security',
		description:
			'Your data is safe with us. Our platform is equipped with advanced security features to protect your privacy and transactions.',
	},
	{
		icon: IconMessage2,
		title: 'Round-the-Clock Support',
		description:
			'Our dedicated support team is available 24/7 to assist you with any queries or concerns, ensuring a smooth shopping experience.',
	},
]
