import { AppShell, Burger, em, Flex, Group, Image, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.png'
import Colors from '../../constants/colors'
import Footer from '../Footer'

export function SimpleNavbar() {
	const navigate = useNavigate()
	const isMobile = useMediaQuery(`(max-width: ${em(576)})`)

	return (
		<AppShell header={{ height: 80 }} footer={{ height: 80, collapsed: true }}>
			<AppShell.Header bg={Colors.primaryColor} withBorder={false}>
				<Group h='100%' w='100%' px='md'>
					<Flex
						w='100%'
						h={isMobile ? '100%' : ''}
						direction={isMobile ? 'column' : 'row'}
						justify={isMobile ? 'space-evenly' : ''}
						align={isMobile ? '' : 'center'}
						gap='sm'
					>
						<Flex
							align='center'
							gap='md'
							hiddenFrom='md'
							justify={isMobile ? 'space-between' : ''}
						>
							<Flex hiddenFrom='md' gap='sm' align='center'>
								<Burger
									onClick={() => navigate('/sign-in')}
									hiddenFrom='sm'
									size='sm'
									color='white'
								/>
								<Text
									onClick={() => navigate('/')}
									fw={600}
									c='white'
									size='xl'
								>
									Tiger it
								</Text>
							</Flex>
						</Flex>
						<Image
							style={{ cursor: 'pointer' }}
							onClick={() => navigate('/')}
							src={logo}
							h='40px'
							w='300px'
							visibleFrom='md'
						/>
						{/* 
						<Flex
							gap={isMobile ? '' : 'sm'}
							visibleFrom='md'
							w='20%'
							justify='end'
						>
							<Anchor c='white' onClick={() => navigate('/sign-in')}>
								Sign In
							</Anchor>
							<Divider orientation='vertical' h={25} />
							<Anchor c='white' onClick={() => navigate('/sign-up')}>
								Sign Up
							</Anchor>
						</Flex> */}
					</Flex>
				</Group>
			</AppShell.Header>
			<AppShell.Main>
				<Outlet />
			</AppShell.Main>
			<Footer />
			<AppShell.Footer></AppShell.Footer>
		</AppShell>
	)
}
