import {
	Avatar,
	Card,
	em,
	Flex,
	Indicator,
	ScrollArea,
	Skeleton,
	Text,
	Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	getChatInboxMessagesAction,
	updateChatSeenByAction,
} from '../../../../store/chat/chatActions.ts'
import { useAppDispatch, useAppSelector } from '../../../../store/store.ts'
import { getUserId } from '../../../../utils/storage.ts'
import { formatMessageTime } from '../../../../utils/timeConverter.ts'

const Inbox = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const { inbox, loaders } = useAppSelector((state) => state.chat)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const userId = getUserId()

	useEffect(() => {
		dispatch(getChatInboxMessagesAction())
	}, [])
	return (
		<Flex w='100%' h='90vh' direction='column' bg='#F1F1F1'>
			<Card w='100%' pos='fixed' style={{ zIndex: '100' }}>
				<Flex w='100%' justify='center'>
					<Flex
						justify='space-between'
						align='center'
						w={isMobile ? '100%' : 1440}
					>
						<Title order={isMobile ? 4 : 3}>Chats</Title>
						{/*<Button radius="xl" color={Colors.primaryColor}>Create Coupon</Button>*/}
					</Flex>
				</Flex>
			</Card>
			<Flex justify='center' mt={60} bg='#F1F1F1'>
				<Flex
					direction='column'
					w={isMobile ? '100%' : 1240}
					p={isMobile ? '' : 'md'}
				>
					<Card radius='md' bg='#F1F1F1' p={0}>
						<ScrollArea h={isMobile ? '80vh' : ''}>
							{inbox?.chats?.length <= 0 ? (
								<Flex
									gap='sm'
									direction='column'
									justify='center'
									align='center'
									h='70vh'
									w='100%'
								>
									<Avatar
										size='xl'
										radius='xs'
										src='https://res.cloudinary.com/de90d6t6e/image/upload/v1712053913/tiger%20it%20v2/web/icons/yjxmthpedulqt2mgumov.png'
									/>
									<Title order={isMobile ? 5 : 3} fw={200}>
										You don't have any messages yet
									</Title>
								</Flex>
							) : (
								inbox?.chats?.map((chat, index) => {
									return (
										<Card p='xs' bg='white' mb='3px'>
											<Flex
												onClick={() => {
													dispatch(
														updateChatSeenByAction({
															chatRoomId: chat?.chatRoomId,
														})
													)
													navigate(
														`/chat/${chat?.chatPartnerInfo?._id}?name=${chat?.chatPartnerInfo?.personalInformation?.firstName}&avatar=${chat?.chatPartnerInfo?.personalInformation?.profilePic}`
													)
												}}
												style={{ cursor: 'pointer' }}
												key={index}
												align='center'
												gap='sm'
												justify='space-between'
											>
												<Flex gap='sm'>
													<Indicator
														color='red'
														disabled={chat?.seenBy === userId}
													>
														{loaders.gettingInbox ? (
															<Skeleton circle height={50} />
														) : (
															<Avatar
																src={
																	chat?.chatPartnerInfo?.personalInformation
																		?.profilePic
																}
															/>
														)}
													</Indicator>
													<Flex
														direction='column'
														gap={loaders.gettingInbox ? 'lg' : ''}
													>
														{loaders.gettingInbox ? (
															<Skeleton height={12} width={100} />
														) : (
															<Text lineClamp={1} size={isMobile ? 'sm' : ''}>
																{
																	chat?.chatPartnerInfo?.personalInformation
																		?.firstName
																}
															</Text>
														)}
														{loaders.gettingInbox ? (
															<Skeleton height={8} width={100} />
														) : (
															<Text
																opacity={0.5}
																size={isMobile ? 'sm' : ''}
																lineClamp={1}
															>
																<div
																	dangerouslySetInnerHTML={{
																		__html: chat?.mostRecentMessage?.text,
																	}}
																/>
															</Text>
														)}
													</Flex>
												</Flex>
												{loaders.gettingInbox ? (
													<Skeleton height={8} width={100} />
												) : (
													<Text opacity={0.5} size={isMobile ? 'xs' : ''}>
														{formatMessageTime(
															chat?.mostRecentMessage?.createdAt
														)}
													</Text>
												)}
											</Flex>
										</Card>
									)
								})
							)}
						</ScrollArea>
					</Card>
				</Flex>
			</Flex>
		</Flex>
	)
}

export default Inbox
