import {
	ActionIcon,
	Avatar,
	Button,
	Card,
	em,
	Flex,
	Group,
	Image,
	Loader,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconHeartFilled, IconPlus } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fallbackImage from '../../../assets/Placholder-01.png'
import { Product } from '../../../interfaces/buyer/product/IProductByCategoryResponse.ts'
import { ISubCategoryProduct } from '../../../interfaces/buyer/product/IProductBySubCategory.ts'
import { IProduct } from '../../../interfaces/buyer/product/IProductResponse.ts'
import {
	addToCartAction,
	removeFromFavouriteAction,
} from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { discountCalculator } from '../../../utils/discountCalculator.ts'
import { getUser, getUserId } from '../../../utils/storage.ts'
import Colors from '../../../constants/colors.ts'

type IProductCardProps = {
	product: IProduct | Product | ISubCategoryProduct
	favourite?: boolean
	ageVerified?: string
}
const ProductInfoCard = ({ product, favourite }: IProductCardProps) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { loaders } = useAppSelector((state) => state.product)
	// const {hovered, ref} = useHover()
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const [favId, setFavId] = useState('')
	const authUser = getUser()
	const isAgeVerificationRejected = authUser?.ageVerification === 'rejected'
	const isAgeVerificationPending = authUser?.ageVerification === 'pending'

	useEffect(() => {
		const id = getUserId()
		if (id) {
			setFavId(id)
		}
	}, [])

	return (
		<Flex
			my='sm'
			w={isMobile ? '150px' : '155px'}
			h={isMobile ? '230px' : '225px'}
			ml='xs'
			style={{ cursor: 'pointer' }}
			onClick={() => {
				navigate(`/products/${product?._id}`)
			}}
		>
			<Card shadow={'xs'} w='100%' pos='relative' radius='sm' p={0}>
				<Flex direction='column' justify='space-between' p='sm'>
					<Flex align='center' justify='center'>
						<Image
							loading='lazy'
							src={product?.productImage}
							alt='image of mobile'
							w={isMobile ? '160px' : '300px'}
							h='130px'
							fallbackSrc={fallbackImage}
						/>
					</Flex>
					{loaders.addingItemToFavourite ? (
						<Loader
							size='sm'
							style={{ position: 'absolute', top: 8, right: 6 }}
						/>
					) : (
						<>
							{favourite && (
								<ActionIcon
									variant='subtle'
									style={{ position: 'absolute', top: 8, right: 6 }}
									color='#E0E0E0'
									onClick={(e) => {
										e.stopPropagation()
										dispatch(
											removeFromFavouriteAction({ productId: product?._id })
										)
									}}
									radius='xl'
								>
									<IconHeartFilled color='blue' style={{ color: Colors.primaryColor }} />
								</ActionIcon>
							)}
							<ActionIcon
								size='sm'
								variant='filled'
								style={{ position: 'absolute', top: 130, right: 6 }}
								color={Colors.primaryColor}
								onClick={(e) => {
									e.stopPropagation()
									if (
										product?.category?.includes('Alcohol') ||
										product?.category?.includes('Smoke Essentials')
									) {
										if (isAgeVerificationRejected) {
											navigate('/buyer/age-verification')
											return
										} else if (isAgeVerificationPending) {
											modals.open({
												// title: 'Age Verification Pending',
												centered: true,
												children: (
													<Flex direction='column' gap='sm'>
														<Flex w='100%' justify='center'>
															<Avatar
																size={isMobile ? '150px' : '200px'}
																radius='xs'
																src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
															/>
														</Flex>
														<Text ta='center' size={isMobile ? 'md' : 'lg'}>
															Your Account is under consideration for Age
															Verification
														</Text>
														<Group justify='center'>
															<Button
																fw={200}
																radius='xl'
																w='30%'
																onClick={() => modals.closeAll()}
															>
																Okay
															</Button>
														</Group>
													</Flex>
												),
											})
											return
										}
									}
									dispatch(addToCartAction({ productId: product?._id }))
								}}
								radius='xl'
							>
								<IconPlus
									color='white'
									size='18px'
									style={{
										color: product?.favoriteOf?.includes(favId)
											? '#C0FF00'
											: '',
									}}
								/>
							</ActionIcon>
						</>
					)}
					<Flex direction='column' py='sm'>
						<Text size='sm' lineClamp={2}>
							{product?.productName}
						</Text>
						<Flex gap='xs'>
							<Text size='sm' fw={700}>
								{discountCalculator(
									product?.productPrice.amount,
									product?.discount
								)}{' '}
								{product?.productPrice.currency}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	)
}

export default ProductInfoCard
