import {
	ActionIcon,
	Badge,
	Button,
	Card,
	em,
	Flex,
	Image,
	Skeleton,
	Text,
	Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconArrowDown } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import wallet from '../../../../assets/wallet-icon.png'
import { useAppDispatch, useAppSelector } from '../../../../store/store.ts'
import { getTransactionHistory } from '../../../../store/wallet/walletActions.ts'
import { dateConverter } from '../../../../utils/dateConverter.ts'
import { round } from '../../../../utils/roundOffFunction.ts'
import Colors from '../../../../constants/colors.ts'

const MyWallet = () => {
	const navigate = useNavigate()
	const { walletWithTransactionHistory, loaders } = useAppSelector(
		(state) => state.wallet
	)
	const dispatch = useAppDispatch()
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	useEffect(() => {
		dispatch(getTransactionHistory())
	}, [])

	return (
		<Flex w='100%' direction='column'>
			<Card w='100%' pos='fixed' style={{ zIndex: '100' }}>
				<Flex w='100%' justify='center'>
					<Flex
						justify='space-between'
						align='center'
						w={isMobile ? '100%' : 1000}
					>
						<Title order={isMobile ? 4 : 3}>My Wallet</Title>
						{/*<Button radius="xl" color={Colors.primaryColor}>Create Coupon</Button>*/}
					</Flex>
				</Flex>
			</Card>
			<Flex justify='center'>
				<Flex direction='column' w={isMobile ? '100%' : 1000} p='md' mt={60}>
					<Card w='100%' h={192} bg={Colors.primaryColor} radius='lg'>
						<Flex justify='space-between'>
							<Flex direction='column' gap='xs'>
								<Text c='white'>Total Balance</Text>
								<Title c='white'>
									{round(walletWithTransactionHistory?.wallet?.balance?.amount)}{' '}
									{walletWithTransactionHistory?.wallet?.balance?.currency}
								</Title>
								<Flex gap='sm'>
									<Button variant='white' c={Colors.primaryColor} radius='xl'>
										Details
									</Button>
									<Button
										variant='outline'
										radius='xl'
										c='white'
										color='white'
										onClick={() => navigate('/deposit-money')}
									>
										Top-ups
									</Button>
								</Flex>
							</Flex>
							<Image
								opacity={0.4}
								pos='absolute'
								right={0}
								h={149}
								top={0}
								w={272}
								src={wallet}
							/>
						</Flex>
					</Card>
					<Card shadow='sm' mt='sm' radius='lg'>
						<Flex justify='space-between' align='center'>
							<Text>Recent Transactions</Text>
							{/*<Text>view all</Text>*/}
						</Flex>
						{loaders.gettingWallet
							? [1, 2, 4, 5, 6, 7].map((_) => (
									<Skeleton my='sm' key={_} h={40} w='100%' />
							  ))
							: walletWithTransactionHistory?.wallet?.transactions?.map(
									(transaction, index) => {
										return (
											<Flex
												key={index}
												w='100%'
												align='center'
												justify='space-between'
												my='xs'
											>
												<Flex align='center' gap='sm'>
													<ActionIcon
														radius='xl'
														color='#F0F1F5'
														c={Colors.primaryColor}
													>
														<IconArrowDown />
													</ActionIcon>
													<Flex direction='column'>
														<Text>{transaction?.title}</Text>
														<Text opacity={0.5}>
															{dateConverter(transaction?.createdAt)}
														</Text>
													</Flex>
												</Flex>
												<Badge
													variant='light'
													color={Colors.primaryColor}
													size='lg'
													radius='sm'
												>
													{round(transaction?.price?.amount)}{' '}
													{transaction?.price?.currency}
												</Badge>
											</Flex>
										)
									}
							  )}
					</Card>
				</Flex>
			</Flex>
		</Flex>
	)
}

export default MyWallet
